import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type TActiveUpdate = {
  __typename?: 'ActiveUpdate';
  project_id: Scalars['ID'];
  visitors: Array<TVisitor>;
};

export type TAnnotation = {
  __typename?: 'Annotation';
  assigned_email: Maybe<Scalars['String']>;
  creation_date: Scalars['String'];
  id: Scalars['ID'];
  is_editable: Scalars['Boolean'];
  last_modification_date: Maybe<Scalars['String']>;
  segment: Maybe<TSegment>;
  status: AnnotationStatusType;
  text: Maybe<Scalars['String']>;
  user: Maybe<TUser>;
  user_id: Scalars['ID'];
};

export enum AnnotationStatusType {
  Assigned = 'ASSIGNED',
  Closed = 'CLOSED',
  NotAssigned = 'NOT_ASSIGNED',
  Resolved = 'RESOLVED',
}

export enum ApplicationType {
  MacApp = 'mac_app',
  ResolveMac = 'resolve_mac',
  ResolveWin = 'resolve_win',
  WebApp = 'web_app',
}

export type TAssembly = {
  __typename?: 'Assembly';
  id: Scalars['ID'];
  segments: Array<TSegment>;
  version: Maybe<Scalars['Int']>;
};

export type TAssemblyProject = {
  __typename?: 'AssemblyProject';
  archived: Scalars['Boolean'];
  assemblyVersions: Array<TAssembly>;
  /** @deprecated No longer supported */
  assembly_versions: Array<TAssembly>;
  cartItems: Maybe<Array<Maybe<TCartItem>>>;
  creationDate: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  creation_date: Maybe<Scalars['String']>;
  durationInSeconds: Maybe<Scalars['Float']>;
  folders: Array<TProjectFolder>;
  fps: Maybe<Scalars['String']>;
  height: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  importedMedia: Array<TMedia>;
  /** @deprecated No longer supported */
  imported_media: Array<TMedia>;
  isOwner: Scalars['Boolean'];
  /** @deprecated No longer supported */
  is_owner: Scalars['Boolean'];
  lastModificationDate: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  last_modification_date: Maybe<Scalars['String']>;
  localState: Maybe<TTestLocalState>;
  myPrivilege: Permission;
  /** @deprecated No longer supported */
  my_privilege: Permission;
  name: Maybe<Scalars['String']>;
  owner: Maybe<TUser>;
  permission: Permission;
  shares: Array<TShare>;
  testLocal: Scalars['Boolean'];
  thumb: Maybe<Scalars['String']>;
  timecode: Maybe<Scalars['String']>;
  user: Maybe<TUser>;
  userId: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  user_id: Maybe<Scalars['Int']>;
  width: Maybe<Scalars['Int']>;
};

export type TAssemblyProjectPublic = {
  __typename?: 'AssemblyProjectPublic';
  archived: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  thumb: Maybe<Scalars['String']>;
};

export type TCartItem = {
  __typename?: 'CartItem';
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
};

export type TChargeCreditResponse = {
  __typename?: 'ChargeCreditResponse';
  cardId: Maybe<Scalars['String']>;
  creditCharged: Maybe<Scalars['Int']>;
  creditUsed: Maybe<Scalars['Int']>;
  defaultSpeechApi: Maybe<Scalars['String']>;
  errorMessage: Maybe<Scalars['String']>;
  errorStatus: Maybe<Scalars['Boolean']>;
  language: Maybe<Scalars['String']>;
  media: Maybe<TMedia>;
};

export type TCompletedPart = {
  ETag: Scalars['String'];
  PartNumber: Scalars['Int'];
};

export type TCreateMultipartPreSignedUrlsResponse = {
  __typename?: 'CreateMultipartPreSignedUrlsResponse';
  fileId: Scalars['String'];
  fileKey: Scalars['String'];
  parts: Array<TPartSignedUrl>;
};

export type TDictionary = {
  __typename?: 'Dictionary';
  creation_date: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Maybe<TLanguage>;
  name: Maybe<Scalars['String']>;
  tags: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TDirectory = {
  __typename?: 'Directory';
  creation_date: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  projects: Maybe<Array<Maybe<TSimonSaysProject>>>;
};

export enum EffectEnum {
  FadeIn = 'FADE_IN',
  FadeOut = 'FADE_OUT',
}

export type TExportRequest = {
  __typename?: 'ExportRequest';
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  status: ExportRequestStatus;
  url: Maybe<Scalars['String']>;
};

export enum ExportRequestStatus {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Ready = 'READY',
  Requested = 'REQUESTED',
}

export type TExportResponse = {
  __typename?: 'ExportResponse';
  base64Payload: Maybe<Scalars['String']>;
  extension: Scalars['String'];
  fileType: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

export enum ExtraEnum {
  Custom_10 = 'CUSTOM_10',
  Custom_20 = 'CUSTOM_20',
  Custom_30 = 'CUSTOM_30',
  Totalcreditrequired = 'TOTALCREDITREQUIRED',
}

export type TFinalizeMultipartUploadResponse = {
  __typename?: 'FinalizeMultipartUploadResponse';
  key: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type TFolder = {
  __typename?: 'Folder';
  archived: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  projects: Array<TAssemblyProject>;
};

export type TIResponse = {
  message: Maybe<Scalars['String']>;
  status: ResponseStatus;
};

export type TInputMedia = {
  audioFilePath?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Float']>;
  fcpxml?: InputMaybe<Scalars['String']>;
  fps?: InputMaybe<Scalars['Float']>;
  frameFilePath?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  localClientPathFile?: InputMaybe<Scalars['String']>;
  originalFilePath?: InputMaybe<Scalars['String']>;
  originalFileUrl?: InputMaybe<Scalars['String']>;
  original_file_url?: InputMaybe<Scalars['String']>;
  prepareProgressPercentage?: InputMaybe<Scalars['Float']>;
  startTimeCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  transcodedVideoProgressPercentage?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  videoFilePath?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type TInputSegment = {
  effect?: InputMaybe<EffectEnum>;
  end?: InputMaybe<Scalars['Float']>;
  /** An identifier that spans multiple assembly versions and can be used to compare segments across values and to attach metadata such as annotations */
  fingerprint?: InputMaybe<Scalars['ID']>;
  imported_media_id?: InputMaybe<Scalars['ID']>;
  is_chapter?: InputMaybe<Scalars['Boolean']>;
  selection?: InputMaybe<TInputSelection>;
  sort_index: Scalars['Int'];
  speaker?: InputMaybe<TSpeakerInput>;
  start?: InputMaybe<Scalars['Float']>;
  style_effect?: InputMaybe<TStyleEffectInput>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SegmentTypeEnum>;
};

export type TInputSelection = {
  anchorIndex?: InputMaybe<Scalars['Int']>;
  anchorKey?: InputMaybe<Scalars['String']>;
  anchorOffset?: InputMaybe<Scalars['Int']>;
  focusIndex?: InputMaybe<Scalars['Int']>;
  focusKey?: InputMaybe<Scalars['String']>;
  focusOffset?: InputMaybe<Scalars['Int']>;
  hasFocus?: InputMaybe<Scalars['Boolean']>;
  isBackward?: InputMaybe<Scalars['Boolean']>;
};

export type TJobTranscribe = {
  __typename?: 'JobTranscribe';
  channels: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['String']>;
  customDictionary: Maybe<Array<Maybe<Scalars['String']>>>;
  diarization: Maybe<Scalars['Boolean']>;
  downsampledAudioUrl: Maybe<Scalars['String']>;
  fileUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  mediaId: Maybe<Scalars['ID']>;
  status: Maybe<Scalars['String']>;
  statusText: Maybe<Scalars['String']>;
};

export type TLanguage = {
  __typename?: 'Language';
  code: Maybe<Scalars['String']>;
  direction: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  label: Maybe<Scalars['String']>;
  languageRegions: Maybe<Array<Maybe<TLanguageRegion>>>;
  /** @deprecated No longer supported */
  language_regions: Maybe<Array<Maybe<TLanguageRegion>>>;
  translation_code: Maybe<Scalars['String']>;
};

export type TLanguageRegion = {
  __typename?: 'LanguageRegion';
  /** @deprecated No longer supported */
  availableForExtension: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  available_for_extension: Maybe<Scalars['Int']>;
  code: Maybe<Scalars['String']>;
  defaultSpeechApi: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  default_speech_api: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  isDiarizationSupported: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  isFavorite: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  is_diarization_supported: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  is_favorite: Maybe<Scalars['Int']>;
  language: Maybe<TLanguage>;
  language_id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
};

export type TLanguageResponse = {
  __typename?: 'LanguageResponse';
  languages: Maybe<Array<Maybe<TLanguage>>>;
  lastUsedTranscriptionLanguageRegion: Maybe<TLanguageRegion>;
  lastUsedTranslationLanguagesRegion: Maybe<Array<Maybe<TLanguage>>>;
};

export type TMedia = {
  __typename?: 'Media';
  applicationType: Maybe<ApplicationType>;
  archived: Scalars['Boolean'];
  bookmarksProcessed: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  dictionaryId: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  dictionary_id: Maybe<Scalars['String']>;
  durationInSeconds: Maybe<Scalars['Float']>;
  endTimeTranscription: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  end_time_transcription: Maybe<Scalars['String']>;
  fcpxml: Maybe<Scalars['String']>;
  fileUploadType: Maybe<TypeInputFile>;
  /** @deprecated No longer supported */
  file_upload_type: Maybe<TypeInputFile>;
  folder: Maybe<TProjectFolder>;
  fps: Maybe<Scalars['Float']>;
  height: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  jobId: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  job_id: Maybe<Scalars['String']>;
  languageRegionCode: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  language_region_code: Maybe<Scalars['String']>;
  localClientPathFile: Maybe<Scalars['String']>;
  muxAssetId: Maybe<Scalars['String']>;
  muxPlaybackId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalFileUrl: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  original_file_url: Maybe<Scalars['String']>;
  percentage: Maybe<Scalars['Int']>;
  progressText: Maybe<Scalars['String']>;
  project: Maybe<TAssemblyProject>;
  projectFolderId: Maybe<Scalars['ID']>;
  projectId: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  project_folder_id: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  project_id: Maybe<Scalars['String']>;
  speakers: Maybe<Array<Maybe<TSpeaker>>>;
  startTimeCode: Maybe<Scalars['String']>;
  startTimeTranscription: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  start_time_transcription: Maybe<Scalars['String']>;
  status: Maybe<MediaStatus>;
  thumb: Maybe<Scalars['String']>;
  transcript: Maybe<Array<TTranscriptRow>>;
  type: Maybe<Scalars['String']>;
  uid: Maybe<Scalars['ID']>;
  url: Maybe<Scalars['String']>;
  user: Maybe<TUser>;
  width: Maybe<Scalars['Int']>;
};

export enum MediaAction {
  Transcribe = 'TRANSCRIBE',
  Translate = 'TRANSLATE',
}

export enum MediaStatus {
  AiAnalyzed = 'ai_analyzed',
  AiAnalyzing = 'ai_analyzing',
  AiFailed = 'ai_failed',
  Downloaded = 'downloaded',
  Downloading = 'downloading',
  Failed = 'failed',
  Ready = 'ready',
  Rejected = 'rejected',
  Transcoding = 'transcoding',
  TranscribePaid = 'transcribe_paid',
  Transcribed = 'transcribed',
  Transcribing = 'transcribing',
  TranslatePaid = 'translate_paid',
  Translated = 'translated',
  Translating = 'translating',
  Uploading = 'uploading',
}

export enum MediaType {
  Audio = 'audio',
  Video = 'video',
}

export type TMutation = {
  __typename?: 'Mutation';
  addNewNotification: TNotification;
  addPermissionForEmails: TAssemblyProject;
  amendAnnotation: TAssembly;
  amendAssemblyProject: Maybe<TAssemblyProject>;
  amendEmailPreferences: TUserEmailPreferences;
  amendFolder: Array<TFolder>;
  amendMedia: TAssemblyProject;
  amendMediaFolder: TAssemblyProject;
  amendPermissionForEmail: TAssemblyProject;
  amendReaction: TAssembly;
  archiveAssemblyProject: TAssemblyProject;
  archiveFolder: Array<TFolder>;
  archiveMediaFolder: TAssemblyProject;
  archiveMediaInAssemblyProject: TAssemblyProject;
  callLambdaSendNotiToMail: Maybe<Scalars['String']>;
  canceledImportMedia: Maybe<TMedia>;
  cartItem: Maybe<TCartItem>;
  changeAssemblyProjectPermission: TAssemblyProject;
  chargeCredit: Maybe<TChargeCreditResponse>;
  chargeExtraCredit: Scalars['Int'];
  cloneAssemblyProject: Maybe<TAssemblyProject>;
  createAnnotation: TAssembly;
  createFolder: Array<TFolder>;
  createMedia: TMedia;
  createMediaFolderInProject: TAssemblyProject;
  createMediaFromYoutube: TMedia;
  createMultipartPreSignedUrls: TCreateMultipartPreSignedUrlsResponse;
  createMuxAsset: Maybe<TMedia>;
  createNewAssemblyProject: TAssemblyProject;
  createSignUrlAws: TSignUrlResponse;
  createSpeaker: Maybe<TSpeaker>;
  deleteNotification: TNotification;
  exportToSocialMedia: Maybe<TShareProjectSocialMedia>;
  finalizeMultipartUpload: Maybe<TFinalizeMultipartUploadResponse>;
  getAvatarSignedUploadUrl: TSignUrlAvatarResponse;
  importSpeakerIntoTranscript: Maybe<TTranscriptRow>;
  markMediaBookmarksAsProcessed: TMedia;
  notifySeverMediaUploadToMux: Maybe<TMedia>;
  onCompleteTranscode: Maybe<TOnTranscodeResponse>;
  onCompleteTranscription: Maybe<TOnTranscriptionResponse>;
  onErrorTranscode: Maybe<TOnTranscodeResponse>;
  onErrorTranscription: Maybe<TOnTranscriptionResponse>;
  onMuxAsset: Maybe<TOnMuxAssetResponse>;
  renameAssemblyProject: TAssemblyProject;
  retrievedTranscribe: TMedia;
  saveAssembly: TAssembly;
  saveTranscript: Maybe<TMedia>;
  subscribePlan: Scalars['String'];
  transcribeMedia: Maybe<TJobTranscribe>;
  translateMedia: Array<Maybe<TMedia>>;
  updateAvatar: TUser;
  updateMedia: Maybe<TMedia>;
  /**
   * updateMediaTranscode
   * Description: receive request from Transcoder instances
   */
  updateMediaTranscode: Scalars['String'];
  updateNotificationsOpenedTime: Maybe<Scalars['String']>;
  updatePlan: Scalars['String'];
  updateReadNotification: TNotification;
  updateSpeaker: Maybe<TSpeaker>;
  updateStatusFailUpload: TMedia;
  updateUserCredit: TUser;
  uploadImportMediaIntoProject: TMedia;
};

export type TMutationAddNewNotificationArgs = {
  action_description?: InputMaybe<Scalars['String']>;
  action_type: NotificationActionType;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type TMutationAddPermissionForEmailsArgs = {
  emails: Array<Scalars['String']>;
  privilege: Permission;
  projectId: Scalars['ID'];
};

export type TMutationAmendAnnotationArgs = {
  annotationId: Scalars['ID'];
  assignedEmail?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type TMutationAmendAssemblyProjectArgs = {
  folderId?: InputMaybe<Scalars['ID']>;
  fps?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['ID'];
  timecode?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type TMutationAmendEmailPreferencesArgs = {
  intercomId: Scalars['ID'];
  subscribedHelp: Scalars['Boolean'];
  subscribedTransactional: Scalars['Boolean'];
  subscribedUpdates: Scalars['Boolean'];
};

export type TMutationAmendFolderArgs = {
  folderId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type TMutationAmendMediaArgs = {
  folderId?: InputMaybe<Scalars['ID']>;
  mediaId: Scalars['ID'];
};

export type TMutationAmendMediaFolderArgs = {
  folderId: Scalars['ID'];
  name: Scalars['String'];
};

export type TMutationAmendPermissionForEmailArgs = {
  email: Scalars['String'];
  privilege: Permission;
  projectId: Scalars['ID'];
};

export type TMutationAmendReactionArgs = {
  remove?: InputMaybe<Scalars['Boolean']>;
  segmentFingerprint: Scalars['ID'];
  type: ReactionType;
};

export type TMutationArchiveAssemblyProjectArgs = {
  archive?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
};

export type TMutationArchiveFolderArgs = {
  archive?: InputMaybe<Scalars['Boolean']>;
  folderId: Scalars['ID'];
};

export type TMutationArchiveMediaFolderArgs = {
  archive?: InputMaybe<Scalars['Boolean']>;
  folderId: Scalars['ID'];
};

export type TMutationArchiveMediaInAssemblyProjectArgs = {
  archive?: InputMaybe<Scalars['Boolean']>;
  mediaId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type TMutationCanceledImportMediaArgs = {
  mediaId: Scalars['ID'];
};

export type TMutationCartItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TMutationChangeAssemblyProjectPermissionArgs = {
  permission?: InputMaybe<Permission>;
  projectId: Scalars['ID'];
};

export type TMutationChargeCreditArgs = {
  defaultSpeechApi: Scalars['String'];
  language: Scalars['String'];
  mediaId: Scalars['ID'];
  type: MediaAction;
};

export type TMutationChargeExtraCreditArgs = {
  cardHolderName?: InputMaybe<Scalars['String']>;
  creditNeedCharge?: InputMaybe<Scalars['Int']>;
  cvc?: InputMaybe<Scalars['String']>;
  expMonth?: InputMaybe<Scalars['Int']>;
  expYear?: InputMaybe<Scalars['Int']>;
  extraType: ExtraEnum;
  number?: InputMaybe<Scalars['String']>;
  useSavedCard: Scalars['Boolean'];
};

export type TMutationCloneAssemblyProjectArgs = {
  projectId: Scalars['ID'];
};

export type TMutationCreateAnnotationArgs = {
  segmentFingerprint: Scalars['ID'];
  text: Scalars['String'];
};

export type TMutationCreateFolderArgs = {
  name: Scalars['String'];
};

export type TMutationCreateMediaArgs = {
  filename: Scalars['String'];
  frameFilePath: Scalars['String'];
  projectId: Scalars['ID'];
  type?: InputMaybe<TypeInputFile>;
};

export type TMutationCreateMediaFolderInProjectArgs = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
};

export type TMutationCreateMediaFromYoutubeArgs = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
  type?: InputMaybe<TypeInputFile>;
  url: Scalars['String'];
};

export type TMutationCreateMultipartPreSignedUrlsArgs = {
  filename: Scalars['String'];
  mediaId: Scalars['ID'];
  mimeType: Scalars['String'];
  parts: Scalars['Int'];
};

export type TMutationCreateMuxAssetArgs = {
  mediaId: Scalars['ID'];
};

export type TMutationCreateNewAssemblyProjectArgs = {
  name?: InputMaybe<Scalars['String']>;
};

export type TMutationCreateSignUrlAwsArgs = {
  filename: Scalars['String'];
  mediaId: Scalars['ID'];
  mimeType: Scalars['String'];
};

export type TMutationCreateSpeakerArgs = {
  color?: InputMaybe<Scalars['String']>;
  mediaId: Scalars['ID'];
  name: Scalars['String'];
};

export type TMutationDeleteNotificationArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type TMutationExportToSocialMediaArgs = {
  projectId: Scalars['ID'];
  share_flatform: Scalars['String'];
};

export type TMutationFinalizeMultipartUploadArgs = {
  input: TFinalizeMultipartUploadInput;
};

export type TMutationGetAvatarSignedUploadUrlArgs = {
  filename: Scalars['String'];
  mimeType: Scalars['String'];
};

export type TMutationImportSpeakerIntoTranscriptArgs = {
  mediaId: Scalars['ID'];
  speakerId: Scalars['ID'];
  transcriptId: Scalars['ID'];
};

export type TMutationMarkMediaBookmarksAsProcessedArgs = {
  mediaId: Scalars['ID'];
};

export type TMutationNotifySeverMediaUploadToMuxArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Float']>;
  fileName: Scalars['String'];
  fileSize?: InputMaybe<Scalars['String']>;
  fps?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  linkToFile?: InputMaybe<Scalars['String']>;
  mediaId: Scalars['ID'];
  type: TypeInputFile;
  typeFile?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type TMutationOnCompleteTranscodeArgs = {
  input: TOnCompleteTranscodeInput;
};

export type TMutationOnCompleteTranscriptionArgs = {
  input: TOnCompleteTranscriptionInput;
};

export type TMutationOnErrorTranscodeArgs = {
  input: TOnErrorTranscodeInput;
};

export type TMutationOnErrorTranscriptionArgs = {
  input: TOnErrorTranscriptionInput;
};

export type TMutationOnMuxAssetArgs = {
  input: TOnMuxAssetInput;
};

export type TMutationRenameAssemblyProjectArgs = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
};

export type TMutationRetrievedTranscribeArgs = {
  jobId: Scalars['String'];
  mediaId: Scalars['ID'];
};

export type TMutationSaveAssemblyArgs = {
  projectId: Scalars['ID'];
  referenceVersion?: InputMaybe<Scalars['Int']>;
  segments: Array<TInputSegment>;
};

export type TMutationSaveTranscriptArgs = {
  mediaId: Scalars['ID'];
  projectId: Scalars['ID'];
  transcriptJson?: InputMaybe<Scalars['String']>;
};

export type TMutationSubscribePlanArgs = {
  planId: Scalars['ID'];
};

export type TMutationTranscribeMediaArgs = {
  defaultSpeechApi: Scalars['String'];
  language: Scalars['String'];
  mediaId: Scalars['ID'];
};

export type TMutationTranslateMediaArgs = {
  mediaId: Scalars['ID'];
  translateCode: Array<InputMaybe<Scalars['String']>>;
};

export type TMutationUpdateAvatarArgs = {
  avatar: Scalars['String'];
};

export type TMutationUpdateMediaArgs = {
  input: TUpdateMediaInput;
};

export type TMutationUpdateMediaTranscodeArgs = {
  errorMessage?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<TInputMedia>;
  serviceType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type TMutationUpdatePlanArgs = {
  planId: Scalars['ID'];
};

export type TMutationUpdateReadNotificationArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type TMutationUpdateSpeakerArgs = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  speakerId: Scalars['ID'];
};

export type TMutationUpdateStatusFailUploadArgs = {
  mediaId: Scalars['ID'];
};

export type TMutationUpdateUserCreditArgs = {
  id: Scalars['ID'];
  input: TUpdateUserCreditInput;
};

export type TMutationUploadImportMediaIntoProjectArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Float']>;
  fileName: Scalars['String'];
  fileSize?: InputMaybe<Scalars['String']>;
  fps?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  linkToFile?: InputMaybe<Scalars['String']>;
  mediaId: Scalars['ID'];
  type: TypeInputFile;
  typeFile?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type TMuxUploadResponse = {
  __typename?: 'MuxUploadResponse';
  url: Maybe<Scalars['String']>;
};

export type TNotification = {
  __typename?: 'Notification';
  action_description: Maybe<Scalars['String']>;
  action_type: NotificationActionType;
  actor: Maybe<TUser>;
  actor_id: Maybe<Scalars['Int']>;
  creation_date: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_read: Maybe<Scalars['Int']>;
  project_encoded_id: Maybe<Scalars['String']>;
  project_id: Maybe<Scalars['Int']>;
  project_name: Maybe<Scalars['String']>;
  user_id: Maybe<Scalars['Int']>;
};

export enum NotificationActionType {
  Assign = 'ASSIGN',
  Comment = 'COMMENT',
  ProjectEdited = 'PROJECT_EDITED',
  ProjectViewed = 'PROJECT_VIEWED',
  Reaction = 'REACTION',
}

export type TNotificationResponse = {
  __typename?: 'NotificationResponse';
  listNotification: Array<Maybe<TNotification>>;
  totalUnseen: Scalars['Int'];
};

export type TOnCompleteTranscodeInput = {
  jobId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TranscribeServiceStatus>;
  url?: InputMaybe<Scalars['String']>;
};

export type TOnCompleteTranscriptionInput = {
  jobId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TranscribeServiceStatus>;
  words?: InputMaybe<Array<InputMaybe<TSsApiWord>>>;
};

export type TOnErrorTranscodeInput = {
  jobId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TranscribeServiceStatus>;
};

export type TOnErrorTranscriptionInput = {
  jobId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TranscribeServiceStatus>;
  words?: InputMaybe<Array<InputMaybe<TSsApiWord>>>;
};

export type TOnMuxAssetInput = {
  durationInSeconds?: InputMaybe<Scalars['Float']>;
  fps?: InputMaybe<Scalars['Float']>;
  height?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  mediaId: Scalars['String'];
  playbackId?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

export type TOnMuxAssetResponse = TIResponse & {
  __typename?: 'OnMuxAssetResponse';
  message: Maybe<Scalars['String']>;
  status: ResponseStatus;
};

export type TOnTranscodeResponse = TIResponse & {
  __typename?: 'OnTranscodeResponse';
  message: Maybe<Scalars['String']>;
  status: ResponseStatus;
};

export type TOnTranscribeResponse = {
  __typename?: 'OnTranscribeResponse';
  mediaId: Scalars['ID'];
  projectId: Scalars['ID'];
  status: MediaStatus;
  transcodingPercentageValue: Maybe<Scalars['Float']>;
};

export type TOnTranscriptionResponse = TIResponse & {
  __typename?: 'OnTranscriptionResponse';
  message: Maybe<Scalars['String']>;
  status: ResponseStatus;
};

export type TPaginationInput = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type TPartSignedUrl = {
  __typename?: 'PartSignedUrl';
  partNumber: Scalars['Int'];
  signedUrl: Scalars['String'];
};

export type TPercentageProcessResponse = {
  __typename?: 'PercentageProcessResponse';
  errorMessage: Maybe<Scalars['String']>;
  mediaId: Maybe<Scalars['ID']>;
  percentageProcessResponse: Maybe<Scalars['Float']>;
};

export enum Permission {
  Comment = 'COMMENT',
  Disabled = 'DISABLED',
  Edit = 'EDIT',
  View = 'VIEW',
}

export type TPlanType = {
  __typename?: 'PlanType';
  active: Maybe<Scalars['Boolean']>;
  credit_in_minutes: Maybe<Scalars['Int']>;
  downgrade_options: Maybe<Array<Maybe<Scalars['String']>>>;
  full_label: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  label: Maybe<Scalars['String']>;
  minimum_charge_in_minutes: Maybe<Scalars['Float']>;
  name: Maybe<Scalars['String']>;
  plan_enum: Maybe<Scalars['String']>;
  price_per_hour: Maybe<Scalars['Float']>;
  stripe_plan_id: Maybe<Scalars['String']>;
  subscription_charge: Maybe<Scalars['Int']>;
  type: Maybe<Typeplan>;
  upgrade_options: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TPrivilege = {
  __typename?: 'Privilege';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type TProgressTranslateResponse = {
  __typename?: 'ProgressTranslateResponse';
  media: Maybe<TMedia>;
  mediaIdTranslate: Maybe<Scalars['ID']>;
  progress: Maybe<Scalars['Float']>;
};

export type TProjectFolder = {
  __typename?: 'ProjectFolder';
  archived: Scalars['Boolean'];
  id: Scalars['ID'];
  imported_media: Array<TMedia>;
  name: Maybe<Scalars['String']>;
};

export type TQuery = {
  __typename?: 'Query';
  cartItems: Maybe<Array<Maybe<TCartItem>>>;
  currentAuthorId: Maybe<Scalars['ID']>;
  getActiveVisitors: Array<TVisitor>;
  getAssemblyFolders: Array<TFolder>;
  getAssemblyMedia: Maybe<TMedia>;
  getAssemblyProject: Maybe<TAssemblyProject>;
  getAssemblyProjectPublicData: Maybe<TAssemblyProjectPublic>;
  getAssemblyProjects: Array<TAssemblyProject>;
  getExport: Maybe<TExportResponse>;
  getIntercomHash: Maybe<Scalars['String']>;
  getLanguages: Maybe<TLanguageResponse>;
  getLatestAssembly: Maybe<TAssembly>;
  getMuxUploadUrl: Maybe<TMuxUploadResponse>;
  getNotifications: TNotificationResponse;
  getPaymentLink: Scalars['String'];
  getPlan: Array<Maybe<TPlanType>>;
  getProjectsByUser: Maybe<Array<Maybe<TAssemblyProject>>>;
  getShareProject: TShareProjectSocialMedia;
  getSpeaker: Maybe<Array<Maybe<TSpeaker>>>;
  getStatusTranscribe: TJobTranscribe;
  getTest: Maybe<Scalars['Int']>;
  getUser: Maybe<TUser>;
  getUserProfile: TUser;
  getUsers: Maybe<Array<Maybe<TUser>>>;
  isOpenListDict: Maybe<Scalars['Boolean']>;
  searchAssemblyProjectMedia: Array<Maybe<TMedia>>;
};

export type TQueryCartItemsArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type TQueryGetActiveVisitorsArgs = {
  projectId: Scalars['ID'];
  session: Scalars['ID'];
};

export type TQueryGetAssemblyMediaArgs = {
  mediaId: Scalars['ID'];
};

export type TQueryGetAssemblyProjectArgs = {
  projectId: Scalars['ID'];
};

export type TQueryGetAssemblyProjectPublicDataArgs = {
  projectId: Scalars['ID'];
};

export type TQueryGetAssemblyProjectsArgs = {
  folderId?: InputMaybe<Scalars['ID']>;
};

export type TQueryGetExportArgs = {
  extension: Scalars['String'];
  fileType: Scalars['String'];
  projectId: Scalars['ID'];
};

export type TQueryGetLatestAssemblyArgs = {
  projectId: Scalars['ID'];
};

export type TQueryGetMuxUploadUrlArgs = {
  filename: Scalars['String'];
  mediaId: Scalars['ID'];
  mimeType: Scalars['String'];
};

export type TQueryGetNotificationsArgs = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type TQueryGetProjectsByUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TQueryGetShareProjectArgs = {
  id: Scalars['Int'];
};

export type TQueryGetSpeakerArgs = {
  mediaId: Scalars['ID'];
};

export type TQueryGetStatusTranscribeArgs = {
  jobId?: InputMaybe<Scalars['String']>;
};

export type TQueryGetTestArgs = {
  text?: InputMaybe<Scalars['String']>;
};

export type TQueryGetUserArgs = {
  id: Scalars['ID'];
};

export type TQuerySearchAssemblyProjectMediaArgs = {
  projectId: Scalars['ID'];
  search: Array<TSearchQuery>;
};

export type TReaction = {
  __typename?: 'Reaction';
  creation_date: Scalars['String'];
  is_editable: Scalars['Boolean'];
  type: ReactionType;
  user: TUser;
};

export enum ReactionType {
  Dislike = 'DISLIKE',
  Laugh = 'LAUGH',
  Like = 'LIKE',
  Love = 'LOVE',
}

export type TResponseDefault = {
  __typename?: 'ResponseDefault';
  message: Maybe<Scalars['String']>;
  statusCode: Maybe<Scalars['Int']>;
};

export enum ResponseStatus {
  Failure = 'failure',
  Success = 'success',
}

export type TRole = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export enum RoleName {
  RoleAdmin = 'ROLE_ADMIN',
  RoleSuperadmin = 'ROLE_SUPERADMIN',
  RoleUnknown = 'ROLE_UNKNOWN',
  RoleUser = 'ROLE_USER',
}

export type TRolePrivileges = {
  __typename?: 'RolePrivileges';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  privileges: Maybe<Array<Maybe<TPrivilege>>>;
};

export type TSsApiWord = {
  endTime?: InputMaybe<Scalars['String']>;
  perWordValue?: InputMaybe<Scalars['String']>;
  speaker?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type TSearchQuery = {
  term: Scalars['String'];
  type: SearchType;
};

export enum SearchType {
  Annotation = 'ANNOTATION',
  Name = 'NAME',
  Speaker = 'SPEAKER',
  Transcript = 'TRANSCRIPT',
}

export type TSegment = {
  __typename?: 'Segment';
  annotations: Array<TAnnotation>;
  assembly_id: Scalars['ID'];
  effect: Maybe<EffectEnum>;
  end: Maybe<Scalars['Float']>;
  /** An identifier that spans multiple assembly versions and can be used to compare segments across values and to attach metadata such as annotations */
  fingerprint: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  imported_media: Maybe<TMedia>;
  is_chapter: Scalars['Boolean'];
  reactions: Array<TReaction>;
  selection: Maybe<TSelection>;
  sort_index: Scalars['Int'];
  speaker: Maybe<TSpeaker>;
  start: Maybe<Scalars['Float']>;
  style_effect: Maybe<TStyleEffect>;
  text: Maybe<Scalars['String']>;
  type: Maybe<SegmentTypeEnum>;
};

export enum SegmentTypeEnum {
  Blackscreen = 'BLACKSCREEN',
  Chapter = 'CHAPTER',
  Media = 'MEDIA',
  Transition = 'TRANSITION',
}

export type TSelection = {
  __typename?: 'Selection';
  anchorIndex: Maybe<Scalars['Int']>;
  anchorKey: Maybe<Scalars['String']>;
  anchorOffset: Maybe<Scalars['Int']>;
  focusIndex: Maybe<Scalars['Int']>;
  focusKey: Maybe<Scalars['String']>;
  focusOffset: Maybe<Scalars['Int']>;
  hasFocus: Maybe<Scalars['Boolean']>;
  isBackward: Maybe<Scalars['Boolean']>;
};

export type TShare = {
  __typename?: 'Share';
  accessed_date: Maybe<Scalars['String']>;
  creation_date: Scalars['String'];
  id: Scalars['ID'];
  invitee: Maybe<Scalars['String']>;
  privilege: Maybe<Permission>;
  project_id: Scalars['ID'];
};

export enum ShareFlatformType {
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
}

export type TShareProjectSocialMedia = {
  __typename?: 'ShareProjectSocialMedia';
  creation_date: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  gif_preview: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  img_preview: Maybe<Scalars['String']>;
  project_id: Scalars['ID'];
  project_name: Maybe<Scalars['String']>;
  share_flatform: Maybe<ShareFlatformType>;
  user: Maybe<TUserShareResponse>;
  user_id: Maybe<Scalars['ID']>;
  video_preview: Maybe<Scalars['String']>;
  video_status: Maybe<VideoStatusType>;
};

export type TSignUrlAvatarResponse = {
  __typename?: 'SignUrlAvatarResponse';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type TSignUrlResponse = {
  __typename?: 'SignUrlResponse';
  key: Scalars['String'];
  mediaId: Scalars['String'];
  url: Scalars['String'];
};

export type TSimonSaysPlanType = {
  __typename?: 'SimonSaysPlanType';
  full_label: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Maybe<Scalars['String']>;
  plan_enum: Maybe<Scalars['String']>;
};

export type TSimonSaysProject = {
  __typename?: 'SimonSaysProject';
  id: Scalars['ID'];
  media: Maybe<Array<Maybe<TMedia>>>;
  name: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

export type TSortInput = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type TSpeaker = {
  __typename?: 'Speaker';
  color: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type TSpeakerInput = {
  color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TStyleEffect = {
  __typename?: 'StyleEffect';
  duration: Maybe<Scalars['Int']>;
};

export type TStyleEffectInput = {
  duration?: InputMaybe<Scalars['Int']>;
};

export type TSubscription = {
  __typename?: 'Subscription';
  activeUpdated: TActiveUpdate;
  assemblyUpdated: TAssembly;
  notificationAdded: TNotification;
  onMediaStatusChanged: TOnTranscribeResponse;
  processTranslateMultipleMedia: TProgressTranslateResponse;
  processUploadFile: TPercentageProcessResponse;
};

export type TSubscriptionActiveUpdatedArgs = {
  projectId: Scalars['ID'];
};

export type TSubscriptionAssemblyUpdatedArgs = {
  projectId: Scalars['ID'];
};

export type TSubscriptionOnMediaStatusChangedArgs = {
  projectId: Scalars['ID'];
};

export type TSubscriptionProcessTranslateMultipleMediaArgs = {
  mediaId: Scalars['ID'];
};

export type TSubscriptionProcessUploadFileArgs = {
  mediaId: Scalars['ID'];
};

export enum SubscriptionStatusEnum {
  Pending = 'PENDING',
  Succeed = 'SUCCEED',
}

export enum Typeplan {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
}

export type TTestLocalState = {
  __typename?: 'TestLocalState';
  state1: Scalars['Int'];
  state2: Scalars['Boolean'];
};

export enum TranscoderJobStatus {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Progressing = 'PROGRESSING',
  Submitted = 'SUBMITTED',
  Ready = 'ready',
}

export enum TranscribeServiceStatus {
  Done = 'done',
  InQueue = 'in_queue',
  Rejected = 'rejected',
  Transcribing = 'transcribing',
}

export type TTranscriptRow = {
  __typename?: 'TranscriptRow';
  annotation: Maybe<Scalars['String']>;
  autoSpeaker: Maybe<Scalars['String']>;
  bookmark: Maybe<Scalars['Boolean']>;
  creation_date: Maybe<Scalars['String']>;
  empty: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  rejected: Maybe<Scalars['Boolean']>;
  speaker: Maybe<TSpeaker>;
  version: Maybe<Scalars['Int']>;
  words: Maybe<Array<TWord>>;
};

export enum TypeInputFile {
  Drive = 'DRIVE',
  Dropbox = 'DROPBOX',
  Local = 'LOCAL',
  Youtube = 'YOUTUBE',
}

export type TUpdateMediaInput = {
  applicationType: ApplicationType;
  audioFilePath?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Float']>;
  /** fcpxml template */
  fcpxml?: InputMaybe<Scalars['String']>;
  fps?: InputMaybe<Scalars['Float']>;
  height?: InputMaybe<Scalars['Int']>;
  localClientPathFile?: InputMaybe<Scalars['String']>;
  mediaId: Scalars['ID'];
  /** update name to match project multi-cam fcp */
  name?: InputMaybe<Scalars['String']>;
  /** the original file link after uploaded to a storage (s3) for Desktop app */
  originalFileUrl?: InputMaybe<Scalars['String']>;
  startTimeCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MediaStatus>;
  type?: InputMaybe<MediaType>;
  /** handle multi-cam project */
  uid?: InputMaybe<Scalars['String']>;
  /** file link after transcoded */
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type TUser = {
  __typename?: 'User';
  amountOfProjects: Maybe<Scalars['Int']>;
  amountOfTranscriptions: Maybe<Scalars['Int']>;
  avatar: Maybe<Scalars['String']>;
  cardId: Maybe<Scalars['String']>;
  card_id: Maybe<Scalars['String']>;
  credit: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['String']>;
  fsUid: Maybe<Scalars['String']>;
  fullName: Maybe<Scalars['String']>;
  full_name: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identities: Maybe<Array<Maybe<Scalars['String']>>>;
  inviteCode: Maybe<Scalars['String']>;
  invite_code: Maybe<Scalars['String']>;
  planType: Maybe<TSimonSaysPlanType>;
  role: Maybe<TRolePrivileges>;
  subscriptionStatus: Maybe<SubscriptionStatusEnum>;
  subscription_status: Maybe<SubscriptionStatusEnum>;
  userId: Scalars['ID'];
  user_id: Scalars['ID'];
  usingEmailAuthentication: Maybe<Scalars['Boolean']>;
  usingGoogleAuth: Maybe<Scalars['Boolean']>;
};

export type TUserEmailPreferences = {
  __typename?: 'UserEmailPreferences';
  intercomId: Scalars['String'];
  subscribedHelp: Scalars['Boolean'];
  subscribedTransactional: Scalars['Boolean'];
  subscribedUpdates: Scalars['Boolean'];
};

export type TUserShareResponse = {
  __typename?: 'UserShareResponse';
  avatar: Maybe<Scalars['String']>;
  full_name: Maybe<Scalars['String']>;
};

export enum VideoStatusType {
  Creating = 'CREATING',
  Finished = 'FINISHED',
}

export type TVisitor = {
  __typename?: 'Visitor';
  active_fingerprint: Maybe<Scalars['ID']>;
  avatar: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  full_name: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  temp_user_id: Maybe<Scalars['ID']>;
  user_id: Maybe<Scalars['ID']>;
};

export type TWord = {
  __typename?: 'Word';
  endTime: Maybe<Scalars['Float']>;
  startTime: Maybe<Scalars['Float']>;
  style: Maybe<Array<Maybe<Scalars['String']>>>;
  text: Maybe<Scalars['String']>;
};

export type TFinalizeMultipartUploadInput = {
  fileId: Scalars['String'];
  fileKey: Scalars['String'];
  parts: Array<TCompletedPart>;
};

/** Description: */
export type TUpdateUserCreditInput = {
  /** Credit in minutes */
  credit: Scalars['Int'];
};

export type TSaveAssemblyMutationVariables = Exact<{
  projectId: Scalars['ID'];
  referenceVersion?: InputMaybe<Scalars['Int']>;
  segments: Array<TInputSegment> | TInputSegment;
}>;

export type TSaveAssemblyMutationResult = {
  __typename?: 'Mutation';
  saveAssembly: {
    __typename?: 'Assembly';
    id: string;
    version: number | null | undefined;
    segments: Array<{
      __typename?: 'Segment';
      id: string;
      fingerprint: string | null | undefined;
      start: number | null | undefined;
      end: number | null | undefined;
      text: string | null | undefined;
      sort_index: number;
      is_chapter: boolean;
      imported_media:
        | {
            __typename?: 'Media';
            id: string;
            name: string;
            description: string | null | undefined;
            url: string | null | undefined;
            startTimeCode: string | null | undefined;
            fps: number | null | undefined;
            type: string | null | undefined;
            height: number | null | undefined;
            width: number | null | undefined;
            durationInSeconds: number | null | undefined;
            archived: boolean;
            project_folder_id: string | null | undefined;
            status: MediaStatus | null | undefined;
            job_id: string | null | undefined;
            start_time_transcription: string | null | undefined;
            file_upload_type: TypeInputFile | null | undefined;
            project_id: string | null | undefined;
            dictionary_id: string | null | undefined;
            language_region_code: string | null | undefined;
            localClientPathFile: string | null | undefined;
            bookmarksProcessed: boolean;
            muxAssetId: string | null | undefined;
            muxPlaybackId: string | null | undefined;
            percentage: number | null | undefined;
            progressText: string | null | undefined;
          }
        | null
        | undefined;
      selection:
        | {
            __typename?: 'Selection';
            anchorKey: string | null | undefined;
            anchorOffset: number | null | undefined;
            focusKey: string | null | undefined;
            focusOffset: number | null | undefined;
            isBackward: boolean | null | undefined;
            hasFocus: boolean | null | undefined;
            anchorIndex: number | null | undefined;
            focusIndex: number | null | undefined;
          }
        | null
        | undefined;
      annotations: Array<{
        __typename?: 'Annotation';
        id: string;
        creation_date: string;
        last_modification_date: string | null | undefined;
        text: string | null | undefined;
        is_editable: boolean;
        user:
          | {
              __typename?: 'User';
              id: string;
              full_name: string | null | undefined;
              email: string | null | undefined;
              avatar: string | null | undefined;
              credit: number | null | undefined;
              cardId: string | null | undefined;
              invite_code: string | null | undefined;
              subscription_status: SubscriptionStatusEnum | null | undefined;
              identities: Array<string | null | undefined> | null | undefined;
              usingEmailAuthentication: boolean | null | undefined;
              usingGoogleAuth: boolean | null | undefined;
            }
          | null
          | undefined;
      }>;
      reactions: Array<{ __typename?: 'Reaction'; type: ReactionType; is_editable: boolean; creation_date: string }>;
      speaker:
        | { __typename?: 'Speaker'; id: string; name: string | null | undefined; color: string | null | undefined }
        | null
        | undefined;
    }>;
  };
};

export type TCreateNewAssemblyProjectMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;

export type TCreateNewAssemblyProjectMutationResult = {
  __typename?: 'Mutation';
  createNewAssemblyProject: { __typename?: 'AssemblyProject'; id: string };
};

export type TCreateMediaFolderInProjectMutationVariables = Exact<{
  name: Scalars['String'];
  projectId: Scalars['ID'];
}>;

export type TCreateMediaFolderInProjectMutationResult = {
  __typename?: 'Mutation';
  createMediaFolderInProject: {
    __typename?: 'AssemblyProject';
    id: string;
    folders: Array<{ __typename?: 'ProjectFolder'; id: string; name: string | null | undefined }>;
  };
};

export type TRenameAssemblyProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  name: Scalars['String'];
}>;

export type TRenameAssemblyProjectMutationResult = {
  __typename?: 'Mutation';
  renameAssemblyProject: { __typename?: 'AssemblyProject'; id: string; name: string | null | undefined };
};

export type TAddPermissionForEmailsMutationVariables = Exact<{
  projectId: Scalars['ID'];
  emails: Array<Scalars['String']> | Scalars['String'];
  privilege: Permission;
}>;

export type TAddPermissionForEmailsMutationResult = {
  __typename?: 'Mutation';
  addPermissionForEmails: {
    __typename?: 'AssemblyProject';
    id: string;
    permission: Permission;
    shares: Array<{
      __typename?: 'Share';
      id: string;
      invitee: string | null | undefined;
      privilege: Permission | null | undefined;
    }>;
  };
};

export type TChangeAssemblyProjectPermissionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  permission: Permission;
}>;

export type TChangeAssemblyProjectPermissionMutationResult = {
  __typename?: 'Mutation';
  changeAssemblyProjectPermission: {
    __typename?: 'AssemblyProject';
    id: string;
    permission: Permission;
    shares: Array<{
      __typename?: 'Share';
      id: string;
      invitee: string | null | undefined;
      privilege: Permission | null | undefined;
    }>;
  };
};

export type TAmendPermissionForEmailMutationVariables = Exact<{
  projectId: Scalars['ID'];
  email: Scalars['String'];
  privilege: Permission;
}>;

export type TAmendPermissionForEmailMutationResult = {
  __typename?: 'Mutation';
  amendPermissionForEmail: {
    __typename?: 'AssemblyProject';
    id: string;
    permission: Permission;
    shares: Array<{
      __typename?: 'Share';
      id: string;
      invitee: string | null | undefined;
      privilege: Permission | null | undefined;
    }>;
  };
};

export type TAmendMediaMutationVariables = Exact<{
  mediaId: Scalars['ID'];
  folderId?: InputMaybe<Scalars['ID']>;
}>;

export type TAmendMediaMutationResult = {
  __typename?: 'Mutation';
  amendMedia: {
    __typename?: 'AssemblyProject';
    id: string;
    folders: Array<{ __typename?: 'ProjectFolder'; id: string; name: string | null | undefined }>;
  };
};

export type TAmendMediaFolderMutationVariables = Exact<{
  folderId: Scalars['ID'];
  name: Scalars['String'];
}>;

export type TAmendMediaFolderMutationResult = {
  __typename?: 'Mutation';
  amendMediaFolder: { __typename?: 'AssemblyProject'; id: string };
};

export type TCreateAnnotationMutationVariables = Exact<{
  segmentFingerprint: Scalars['ID'];
  text: Scalars['String'];
}>;

export type TCreateAnnotationMutationResult = {
  __typename?: 'Mutation';
  createAnnotation: {
    __typename?: 'Assembly';
    id: string;
    version: number | null | undefined;
    segments: Array<{
      __typename?: 'Segment';
      id: string;
      annotations: Array<{
        __typename?: 'Annotation';
        id: string;
        creation_date: string;
        last_modification_date: string | null | undefined;
        text: string | null | undefined;
        is_editable: boolean;
        user:
          | {
              __typename?: 'User';
              user_id: string;
              full_name: string | null | undefined;
              email: string | null | undefined;
              avatar: string | null | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
  };
};

export type TAmendAssemblyProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  fps?: InputMaybe<Scalars['String']>;
  timecode?: InputMaybe<Scalars['String']>;
}>;

export type TAmendAssemblyProjectMutationResult = {
  __typename?: 'Mutation';
  amendAssemblyProject: { __typename?: 'AssemblyProject'; id: string } | null | undefined;
};

export type TAmendAnnotationMutationVariables = Exact<{
  annotationId: Scalars['ID'];
  text: Scalars['String'];
}>;

export type TAmendAnnotationMutationResult = {
  __typename?: 'Mutation';
  amendAnnotation: {
    __typename?: 'Assembly';
    id: string;
    version: number | null | undefined;
    segments: Array<{
      __typename?: 'Segment';
      id: string;
      annotations: Array<{
        __typename?: 'Annotation';
        id: string;
        creation_date: string;
        last_modification_date: string | null | undefined;
        text: string | null | undefined;
        is_editable: boolean;
        user:
          | {
              __typename?: 'User';
              user_id: string;
              full_name: string | null | undefined;
              email: string | null | undefined;
              avatar: string | null | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
  };
};

export type TAmendReactionMutationVariables = Exact<{
  segmentFingerprint: Scalars['ID'];
  type: ReactionType;
  remove?: InputMaybe<Scalars['Boolean']>;
}>;

export type TAmendReactionMutationResult = {
  __typename?: 'Mutation';
  amendReaction: {
    __typename?: 'Assembly';
    id: string;
    version: number | null | undefined;
    segments: Array<{
      __typename?: 'Segment';
      id: string;
      reactions: Array<{ __typename?: 'Reaction'; type: ReactionType; is_editable: boolean }>;
    }>;
  };
};

export type TMarkMediaBookmarksAsProcessedMutationVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type TMarkMediaBookmarksAsProcessedMutationResult = {
  __typename?: 'Mutation';
  markMediaBookmarksAsProcessed: {
    __typename?: 'Media';
    id: string;
    name: string;
    url: string | null | undefined;
    startTimeCode: string | null | undefined;
    fps: number | null | undefined;
    localClientPathFile: string | null | undefined;
    bookmarksProcessed: boolean;
    transcript:
      | Array<{
          __typename?: 'TranscriptRow';
          annotation: string | null | undefined;
          words:
            | Array<{
                __typename?: 'Word';
                text: string | null | undefined;
                startTime: number | null | undefined;
                endTime: number | null | undefined;
                style: Array<string | null | undefined> | null | undefined;
              }>
            | null
            | undefined;
          speaker:
            | { __typename?: 'Speaker'; id: string; name: string | null | undefined; color: string | null | undefined }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type TArchiveAssemblyProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  archive: Scalars['Boolean'];
}>;

export type TArchiveAssemblyProjectMutationResult = {
  __typename?: 'Mutation';
  archiveAssemblyProject: {
    __typename?: 'AssemblyProject';
    id: string;
    name: string | null | undefined;
    archived: boolean;
  };
};

export type TArchiveMediaInAssemblyProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  mediaId: Scalars['ID'];
}>;

export type TArchiveMediaInAssemblyProjectMutationResult = {
  __typename?: 'Mutation';
  archiveMediaInAssemblyProject: {
    __typename?: 'AssemblyProject';
    id: string;
    imported_media: Array<{
      __typename?: 'Media';
      id: string;
      name: string;
      url: string | null | undefined;
      startTimeCode: string | null | undefined;
      fps: number | null | undefined;
      type: string | null | undefined;
      height: number | null | undefined;
      width: number | null | undefined;
      durationInSeconds: number | null | undefined;
      archived: boolean;
      project_folder_id: string | null | undefined;
      project_id: string | null | undefined;
      status: MediaStatus | null | undefined;
      job_id: string | null | undefined;
      start_time_transcription: string | null | undefined;
      file_upload_type: TypeInputFile | null | undefined;
      dictionary_id: string | null | undefined;
      language_region_code: string | null | undefined;
    }>;
  };
};

export type TArchiveMediaFolderMutationVariables = Exact<{
  folderId: Scalars['ID'];
  archive?: InputMaybe<Scalars['Boolean']>;
}>;

export type TArchiveMediaFolderMutationResult = {
  __typename?: 'Mutation';
  archiveMediaFolder: { __typename?: 'AssemblyProject'; id: string };
};

export type TCreateMediaMutationVariables = Exact<{
  filename: Scalars['String'];
  frameFilePath: Scalars['String'];
  projectId: Scalars['ID'];
  type?: InputMaybe<TypeInputFile>;
}>;

export type TCreateMediaMutationResult = {
  __typename?: 'Mutation';
  createMedia: {
    __typename?: 'Media';
    id: string;
    name: string;
    description: string | null | undefined;
    url: string | null | undefined;
    startTimeCode: string | null | undefined;
    fps: number | null | undefined;
    type: string | null | undefined;
    height: number | null | undefined;
    width: number | null | undefined;
    durationInSeconds: number | null | undefined;
    archived: boolean;
    project_folder_id: string | null | undefined;
    status: MediaStatus | null | undefined;
    job_id: string | null | undefined;
    start_time_transcription: string | null | undefined;
    file_upload_type: TypeInputFile | null | undefined;
    project_id: string | null | undefined;
    dictionary_id: string | null | undefined;
    language_region_code: string | null | undefined;
    localClientPathFile: string | null | undefined;
    bookmarksProcessed: boolean;
    muxAssetId: string | null | undefined;
    muxPlaybackId: string | null | undefined;
    percentage: number | null | undefined;
    progressText: string | null | undefined;
  };
};

export type TCreateSignUrlAwsMutationVariables = Exact<{
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  mediaId: Scalars['ID'];
}>;

export type TCreateSignUrlAwsMutationResult = {
  __typename?: 'Mutation';
  createSignUrlAws: { __typename?: 'SignUrlResponse'; key: string; url: string; mediaId: string };
};

export type TCreateMultipartPreSignedUrlsMutationVariables = Exact<{
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  mediaId: Scalars['ID'];
  parts: Scalars['Int'];
}>;

export type TCreateMultipartPreSignedUrlsMutationResult = {
  __typename?: 'Mutation';
  createMultipartPreSignedUrls: {
    __typename?: 'CreateMultipartPreSignedUrlsResponse';
    fileId: string;
    fileKey: string;
    parts: Array<{ __typename?: 'PartSignedUrl'; partNumber: number; signedUrl: string }>;
  };
};

export type TFinalizeMultipartUploadMutationVariables = Exact<{
  input: TFinalizeMultipartUploadInput;
}>;

export type TFinalizeMultipartUploadMutationResult = {
  __typename?: 'Mutation';
  finalizeMultipartUpload:
    | { __typename?: 'FinalizeMultipartUploadResponse'; url: string | null | undefined; key: string | null | undefined }
    | null
    | undefined;
};

export type TCloneAssemblyProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type TCloneAssemblyProjectMutationResult = {
  __typename?: 'Mutation';
  cloneAssemblyProject: { __typename?: 'AssemblyProject'; id: string } | null | undefined;
};

export type TUploadImportMediaIntoProjectMutationVariables = Exact<{
  mediaId: Scalars['ID'];
  fileName: Scalars['String'];
  type: TypeInputFile;
  linkToFile?: InputMaybe<Scalars['String']>;
  accessToken?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Float']>;
}>;

export type TUploadImportMediaIntoProjectMutationResult = {
  __typename?: 'Mutation';
  uploadImportMediaIntoProject: {
    __typename?: 'Media';
    id: string;
    name: string;
    description: string | null | undefined;
    url: string | null | undefined;
    startTimeCode: string | null | undefined;
    fps: number | null | undefined;
    type: string | null | undefined;
    height: number | null | undefined;
    width: number | null | undefined;
    durationInSeconds: number | null | undefined;
    archived: boolean;
    project_folder_id: string | null | undefined;
    status: MediaStatus | null | undefined;
    job_id: string | null | undefined;
    start_time_transcription: string | null | undefined;
    file_upload_type: TypeInputFile | null | undefined;
    project_id: string | null | undefined;
    dictionary_id: string | null | undefined;
    language_region_code: string | null | undefined;
    localClientPathFile: string | null | undefined;
    bookmarksProcessed: boolean;
    muxAssetId: string | null | undefined;
    muxPlaybackId: string | null | undefined;
    percentage: number | null | undefined;
    progressText: string | null | undefined;
  };
};

export type TNotifySeverMediaUploadToMuxMutationVariables = Exact<{
  mediaId: Scalars['ID'];
  fileName: Scalars['String'];
  type: TypeInputFile;
  linkToFile?: InputMaybe<Scalars['String']>;
  accessToken?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Float']>;
}>;

export type TNotifySeverMediaUploadToMuxMutationResult = {
  __typename?: 'Mutation';
  notifySeverMediaUploadToMux:
    | {
        __typename?: 'Media';
        id: string;
        name: string;
        description: string | null | undefined;
        url: string | null | undefined;
        startTimeCode: string | null | undefined;
        fps: number | null | undefined;
        type: string | null | undefined;
        height: number | null | undefined;
        width: number | null | undefined;
        durationInSeconds: number | null | undefined;
        archived: boolean;
        project_folder_id: string | null | undefined;
        status: MediaStatus | null | undefined;
        job_id: string | null | undefined;
        start_time_transcription: string | null | undefined;
        file_upload_type: TypeInputFile | null | undefined;
        project_id: string | null | undefined;
        dictionary_id: string | null | undefined;
        language_region_code: string | null | undefined;
        localClientPathFile: string | null | undefined;
        bookmarksProcessed: boolean;
        muxAssetId: string | null | undefined;
        muxPlaybackId: string | null | undefined;
        percentage: number | null | undefined;
        progressText: string | null | undefined;
      }
    | null
    | undefined;
};

export type TTranscribeMediaMutationVariables = Exact<{
  mediaId: Scalars['ID'];
  language: Scalars['String'];
  defaultSpeechApi: Scalars['String'];
}>;

export type TTranscribeMediaMutationResult = {
  __typename?: 'Mutation';
  transcribeMedia:
    | {
        __typename?: 'JobTranscribe';
        id: string | null | undefined;
        mediaId: string | null | undefined;
        status: string | null | undefined;
        statusText: string | null | undefined;
      }
    | null
    | undefined;
};

export type TUpdateReadNotificationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type TUpdateReadNotificationMutationResult = {
  __typename?: 'Mutation';
  updateReadNotification: { __typename?: 'Notification'; id: string };
};

export type TDeleteNotificationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type TDeleteNotificationMutationResult = {
  __typename?: 'Mutation';
  deleteNotification: { __typename?: 'Notification'; id: string };
};

export type TRetrievedTranscribeMutationVariables = Exact<{
  jobId: Scalars['String'];
  mediaId: Scalars['ID'];
}>;

export type TRetrievedTranscribeMutationResult = {
  __typename?: 'Mutation';
  retrievedTranscribe: {
    __typename?: 'Media';
    id: string;
    name: string;
    description: string | null | undefined;
    url: string | null | undefined;
    startTimeCode: string | null | undefined;
    fps: number | null | undefined;
    type: string | null | undefined;
    height: number | null | undefined;
    width: number | null | undefined;
    durationInSeconds: number | null | undefined;
    archived: boolean;
    project_folder_id: string | null | undefined;
    status: MediaStatus | null | undefined;
    job_id: string | null | undefined;
    start_time_transcription: string | null | undefined;
    file_upload_type: TypeInputFile | null | undefined;
    project_id: string | null | undefined;
    dictionary_id: string | null | undefined;
    language_region_code: string | null | undefined;
    localClientPathFile: string | null | undefined;
    bookmarksProcessed: boolean;
    muxAssetId: string | null | undefined;
    muxPlaybackId: string | null | undefined;
    percentage: number | null | undefined;
    progressText: string | null | undefined;
  };
};

export type TTranslateMediaMutationVariables = Exact<{
  mediaId: Scalars['ID'];
  translateCode: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type TTranslateMediaMutationResult = {
  __typename?: 'Mutation';
  translateMedia: Array<
    | {
        __typename?: 'Media';
        id: string;
        name: string;
        description: string | null | undefined;
        url: string | null | undefined;
        startTimeCode: string | null | undefined;
        fps: number | null | undefined;
        type: string | null | undefined;
        height: number | null | undefined;
        width: number | null | undefined;
        durationInSeconds: number | null | undefined;
        archived: boolean;
        project_folder_id: string | null | undefined;
        status: MediaStatus | null | undefined;
        job_id: string | null | undefined;
        start_time_transcription: string | null | undefined;
        file_upload_type: TypeInputFile | null | undefined;
        project_id: string | null | undefined;
        dictionary_id: string | null | undefined;
        language_region_code: string | null | undefined;
        localClientPathFile: string | null | undefined;
        bookmarksProcessed: boolean;
        muxAssetId: string | null | undefined;
        muxPlaybackId: string | null | undefined;
        percentage: number | null | undefined;
        progressText: string | null | undefined;
      }
    | null
    | undefined
  >;
};

export type TUpdateNotificationsOpenedTimeMutationVariables = Exact<{ [key: string]: never }>;

export type TUpdateNotificationsOpenedTimeMutationResult = {
  __typename?: 'Mutation';
  updateNotificationsOpenedTime: string | null | undefined;
};

export type TAmendEmailPreferencesMutationVariables = Exact<{
  intercomId: Scalars['ID'];
  subscribedHelp: Scalars['Boolean'];
  subscribedUpdates: Scalars['Boolean'];
  subscribedTransactional: Scalars['Boolean'];
}>;

export type TAmendEmailPreferencesMutationResult = {
  __typename?: 'Mutation';
  amendEmailPreferences: {
    __typename?: 'UserEmailPreferences';
    intercomId: string;
    subscribedHelp: boolean;
    subscribedUpdates: boolean;
    subscribedTransactional: boolean;
  };
};

export type TSaveTranscriptMutationVariables = Exact<{
  projectId: Scalars['ID'];
  mediaId: Scalars['ID'];
  transcriptJson?: InputMaybe<Scalars['String']>;
}>;

export type TSaveTranscriptMutationResult = {
  __typename?: 'Mutation';
  saveTranscript: { __typename?: 'Media'; id: string } | null | undefined;
};

export type TChargeCreditMutationVariables = Exact<{
  mediaId: Scalars['ID'];
  language: Scalars['String'];
  defaultSpeechApi: Scalars['String'];
  type: MediaAction;
}>;

export type TChargeCreditMutationResult = {
  __typename?: 'Mutation';
  chargeCredit:
    | {
        __typename?: 'ChargeCreditResponse';
        errorStatus: boolean | null | undefined;
        errorMessage: string | null | undefined;
        creditUsed: number | null | undefined;
        creditCharged: number | null | undefined;
        cardId: string | null | undefined;
        language: string | null | undefined;
        defaultSpeechApi: string | null | undefined;
        media:
          | {
              __typename?: 'Media';
              id: string;
              status: MediaStatus | null | undefined;
              durationInSeconds: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TSubScribePlanMutationVariables = Exact<{
  planId: Scalars['ID'];
}>;

export type TSubScribePlanMutationResult = { __typename?: 'Mutation'; subscribePlan: string };

export type TUpdatePlanMutationVariables = Exact<{
  planId: Scalars['ID'];
}>;

export type TUpdatePlanMutationResult = { __typename?: 'Mutation'; updatePlan: string };

export type TCreateSpeakerMutationVariables = Exact<{
  mediaId: Scalars['ID'];
  name: Scalars['String'];
  color?: InputMaybe<Scalars['String']>;
}>;

export type TCreateSpeakerMutationResult = {
  __typename?: 'Mutation';
  createSpeaker:
    | { __typename?: 'Speaker'; id: string; name: string | null | undefined; color: string | null | undefined }
    | null
    | undefined;
};

export type TUpdateSpeakerMutationVariables = Exact<{
  speakerId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
}>;

export type TUpdateSpeakerMutationResult = {
  __typename?: 'Mutation';
  updateSpeaker:
    | { __typename?: 'Speaker'; id: string; name: string | null | undefined; color: string | null | undefined }
    | null
    | undefined;
};

export type TChargeExtraCreditMutationVariables = Exact<{
  extraType: ExtraEnum;
  creditNeedCharge?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['String']>;
  cardHolderName?: InputMaybe<Scalars['String']>;
  cvc?: InputMaybe<Scalars['String']>;
  expMonth?: InputMaybe<Scalars['Int']>;
  expYear?: InputMaybe<Scalars['Int']>;
  useSavedCard: Scalars['Boolean'];
}>;

export type TChargeExtraCreditMutationResult = { __typename?: 'Mutation'; chargeExtraCredit: number };

export type TUpdateAvatarMutationVariables = Exact<{
  avatar: Scalars['String'];
}>;

export type TUpdateAvatarMutationResult = {
  __typename?: 'Mutation';
  updateAvatar: {
    __typename?: 'User';
    user_id: string;
    full_name: string | null | undefined;
    email: string | null | undefined;
    avatar: string | null | undefined;
    invite_code: string | null | undefined;
    planType:
      | {
          __typename?: 'SimonSaysPlanType';
          id: string;
          label: string | null | undefined;
          plan_enum: string | null | undefined;
          full_label: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type TGetAvatarSignedUploadUrlMutationVariables = Exact<{
  filename: Scalars['String'];
  mimeType: Scalars['String'];
}>;

export type TGetAvatarSignedUploadUrlMutationResult = {
  __typename?: 'Mutation';
  getAvatarSignedUploadUrl: { __typename?: 'SignUrlAvatarResponse'; key: string; url: string };
};

export type TExportToSocialMediaMutationVariables = Exact<{
  projectId: Scalars['ID'];
  share_flatform: Scalars['String'];
}>;

export type TExportToSocialMediaMutationResult = {
  __typename?: 'Mutation';
  exportToSocialMedia:
    | {
        __typename?: 'ShareProjectSocialMedia';
        id: string;
        project_name: string | null | undefined;
        description: string | null | undefined;
      }
    | null
    | undefined;
};

export type TUpdateStatusFailUploadMutationVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type TUpdateStatusFailUploadMutationResult = {
  __typename?: 'Mutation';
  updateStatusFailUpload: {
    __typename?: 'Media';
    id: string;
    name: string;
    url: string | null | undefined;
    startTimeCode: string | null | undefined;
    fps: number | null | undefined;
    type: string | null | undefined;
    height: number | null | undefined;
    width: number | null | undefined;
    durationInSeconds: number | null | undefined;
    project_folder_id: string | null | undefined;
    status: MediaStatus | null | undefined;
    file_upload_type: TypeInputFile | null | undefined;
    project_id: string | null | undefined;
  };
};

export type TCanceledImportMediaMutationVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type TCanceledImportMediaMutationResult = {
  __typename?: 'Mutation';
  canceledImportMedia:
    | {
        __typename?: 'Media';
        id: string;
        name: string;
        url: string | null | undefined;
        startTimeCode: string | null | undefined;
        fps: number | null | undefined;
        type: string | null | undefined;
        height: number | null | undefined;
        width: number | null | undefined;
        durationInSeconds: number | null | undefined;
        archived: boolean;
        project_folder_id: string | null | undefined;
        status: MediaStatus | null | undefined;
        job_id: string | null | undefined;
        start_time_transcription: string | null | undefined;
        file_upload_type: TypeInputFile | null | undefined;
        project_id: string | null | undefined;
      }
    | null
    | undefined;
};

export type TAmendAssemblyMediaMutationVariables = Exact<{
  input: TUpdateMediaInput;
}>;

export type TAmendAssemblyMediaMutationResult = {
  __typename?: 'Mutation';
  updateMedia:
    | { __typename?: 'Media'; id: string; name: string; description: string | null | undefined }
    | null
    | undefined;
};

export type TAddCartItemMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
}>;

export type TAddCartItemMutationResult = {
  __typename?: 'Mutation';
  cartItem:
    | { __typename?: 'CartItem'; id: string | null | undefined; name: string | null | undefined }
    | null
    | undefined;
};

export type TGetAssemblyProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type TGetAssemblyProjectsQueryResult = {
  __typename?: 'Query';
  getAssemblyProjects: Array<{
    __typename?: 'AssemblyProject';
    id: string;
    name: string | null | undefined;
    creation_date: string | null | undefined;
    is_owner: boolean;
    thumb: string | null | undefined;
    height: number | null | undefined;
    width: number | null | undefined;
    timecode: string | null | undefined;
    fps: string | null | undefined;
    last_modification_date: string | null | undefined;
    durationInSeconds: number | null | undefined;
    permission: Permission;
    my_privilege: Permission;
    archived: boolean;
    shares: Array<{ __typename?: 'Share'; id: string; invitee: string | null | undefined }>;
    owner:
      | {
          __typename?: 'User';
          full_name: string | null | undefined;
          email: string | null | undefined;
          avatar: string | null | undefined;
        }
      | null
      | undefined;
    imported_media: Array<{ __typename?: 'Media'; id: string }>;
  }>;
};

export type TGetAssemblyProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type TGetAssemblyProjectQueryResult = {
  __typename?: 'Query';
  getAssemblyProject:
    | {
        __typename?: 'AssemblyProject';
        id: string;
        name: string | null | undefined;
        creation_date: string | null | undefined;
        is_owner: boolean;
        thumb: string | null | undefined;
        height: number | null | undefined;
        width: number | null | undefined;
        timecode: string | null | undefined;
        fps: string | null | undefined;
        last_modification_date: string | null | undefined;
        durationInSeconds: number | null | undefined;
        permission: Permission;
        my_privilege: Permission;
        archived: boolean;
        imported_media: Array<{
          __typename?: 'Media';
          id: string;
          name: string;
          description: string | null | undefined;
          url: string | null | undefined;
          startTimeCode: string | null | undefined;
          fps: number | null | undefined;
          type: string | null | undefined;
          height: number | null | undefined;
          width: number | null | undefined;
          durationInSeconds: number | null | undefined;
          archived: boolean;
          project_folder_id: string | null | undefined;
          status: MediaStatus | null | undefined;
          job_id: string | null | undefined;
          start_time_transcription: string | null | undefined;
          file_upload_type: TypeInputFile | null | undefined;
          project_id: string | null | undefined;
          dictionary_id: string | null | undefined;
          language_region_code: string | null | undefined;
          localClientPathFile: string | null | undefined;
          bookmarksProcessed: boolean;
          muxAssetId: string | null | undefined;
          muxPlaybackId: string | null | undefined;
          percentage: number | null | undefined;
          progressText: string | null | undefined;
        }>;
        shares: Array<{
          __typename?: 'Share';
          id: string;
          invitee: string | null | undefined;
          privilege: Permission | null | undefined;
        }>;
        folders: Array<{
          __typename?: 'ProjectFolder';
          id: string;
          name: string | null | undefined;
          archived: boolean;
          imported_media: Array<{
            __typename?: 'Media';
            id: string;
            name: string;
            description: string | null | undefined;
            url: string | null | undefined;
            startTimeCode: string | null | undefined;
            fps: number | null | undefined;
            type: string | null | undefined;
            height: number | null | undefined;
            width: number | null | undefined;
            durationInSeconds: number | null | undefined;
            archived: boolean;
            project_folder_id: string | null | undefined;
            status: MediaStatus | null | undefined;
            job_id: string | null | undefined;
            start_time_transcription: string | null | undefined;
            file_upload_type: TypeInputFile | null | undefined;
            project_id: string | null | undefined;
            dictionary_id: string | null | undefined;
            language_region_code: string | null | undefined;
            localClientPathFile: string | null | undefined;
            bookmarksProcessed: boolean;
            muxAssetId: string | null | undefined;
            muxPlaybackId: string | null | undefined;
            percentage: number | null | undefined;
            progressText: string | null | undefined;
          }>;
        }>;
      }
    | null
    | undefined;
};

export type TGetAssemblyMediaQueryVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type TGetAssemblyMediaQueryResult = {
  __typename?: 'Query';
  getAssemblyMedia:
    | {
        __typename?: 'Media';
        id: string;
        name: string;
        description: string | null | undefined;
        url: string | null | undefined;
        startTimeCode: string | null | undefined;
        fps: number | null | undefined;
        type: string | null | undefined;
        height: number | null | undefined;
        width: number | null | undefined;
        durationInSeconds: number | null | undefined;
        archived: boolean;
        project_folder_id: string | null | undefined;
        status: MediaStatus | null | undefined;
        job_id: string | null | undefined;
        start_time_transcription: string | null | undefined;
        file_upload_type: TypeInputFile | null | undefined;
        project_id: string | null | undefined;
        dictionary_id: string | null | undefined;
        language_region_code: string | null | undefined;
        localClientPathFile: string | null | undefined;
        bookmarksProcessed: boolean;
        muxAssetId: string | null | undefined;
        muxPlaybackId: string | null | undefined;
        percentage: number | null | undefined;
        progressText: string | null | undefined;
        folder:
          | { __typename?: 'ProjectFolder'; id: string; name: string | null | undefined; archived: boolean }
          | null
          | undefined;
        transcript:
          | Array<{
              __typename?: 'TranscriptRow';
              annotation: string | null | undefined;
              autoSpeaker: string | null | undefined;
              words:
                | Array<{
                    __typename?: 'Word';
                    text: string | null | undefined;
                    startTime: number | null | undefined;
                    endTime: number | null | undefined;
                    style: Array<string | null | undefined> | null | undefined;
                  }>
                | null
                | undefined;
              speaker:
                | {
                    __typename?: 'Speaker';
                    id: string;
                    name: string | null | undefined;
                    color: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TGetLatestAssemblyQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type TGetLatestAssemblyQueryResult = {
  __typename?: 'Query';
  getLatestAssembly:
    | {
        __typename?: 'Assembly';
        id: string;
        version: number | null | undefined;
        segments: Array<{
          __typename?: 'Segment';
          id: string;
          fingerprint: string | null | undefined;
          start: number | null | undefined;
          end: number | null | undefined;
          text: string | null | undefined;
          sort_index: number;
          is_chapter: boolean;
          imported_media:
            | {
                __typename?: 'Media';
                id: string;
                name: string;
                description: string | null | undefined;
                url: string | null | undefined;
                startTimeCode: string | null | undefined;
                fps: number | null | undefined;
                type: string | null | undefined;
                height: number | null | undefined;
                width: number | null | undefined;
                durationInSeconds: number | null | undefined;
                archived: boolean;
                project_folder_id: string | null | undefined;
                status: MediaStatus | null | undefined;
                job_id: string | null | undefined;
                start_time_transcription: string | null | undefined;
                file_upload_type: TypeInputFile | null | undefined;
                project_id: string | null | undefined;
                dictionary_id: string | null | undefined;
                language_region_code: string | null | undefined;
                localClientPathFile: string | null | undefined;
                bookmarksProcessed: boolean;
                muxAssetId: string | null | undefined;
                muxPlaybackId: string | null | undefined;
                percentage: number | null | undefined;
                progressText: string | null | undefined;
              }
            | null
            | undefined;
          selection:
            | {
                __typename?: 'Selection';
                anchorKey: string | null | undefined;
                anchorOffset: number | null | undefined;
                focusKey: string | null | undefined;
                focusOffset: number | null | undefined;
                isBackward: boolean | null | undefined;
                hasFocus: boolean | null | undefined;
                anchorIndex: number | null | undefined;
                focusIndex: number | null | undefined;
              }
            | null
            | undefined;
          annotations: Array<{
            __typename?: 'Annotation';
            id: string;
            creation_date: string;
            last_modification_date: string | null | undefined;
            text: string | null | undefined;
            is_editable: boolean;
            user:
              | {
                  __typename?: 'User';
                  id: string;
                  full_name: string | null | undefined;
                  email: string | null | undefined;
                  avatar: string | null | undefined;
                  credit: number | null | undefined;
                  cardId: string | null | undefined;
                  invite_code: string | null | undefined;
                  subscription_status: SubscriptionStatusEnum | null | undefined;
                  identities: Array<string | null | undefined> | null | undefined;
                  usingEmailAuthentication: boolean | null | undefined;
                  usingGoogleAuth: boolean | null | undefined;
                }
              | null
              | undefined;
          }>;
          reactions: Array<{
            __typename?: 'Reaction';
            type: ReactionType;
            is_editable: boolean;
            creation_date: string;
          }>;
          speaker:
            | { __typename?: 'Speaker'; id: string; name: string | null | undefined; color: string | null | undefined }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type TGetActiveVisitorsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  session: Scalars['ID'];
}>;

export type TGetActiveVisitorsQueryResult = {
  __typename?: 'Query';
  getActiveVisitors: Array<{
    __typename?: 'Visitor';
    id: string;
    user_id: string | null | undefined;
    full_name: string | null | undefined;
    email: string | null | undefined;
    avatar: string | null | undefined;
    temp_user_id: string | null | undefined;
    active_fingerprint: string | null | undefined;
  }>;
};

export type TGetLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type TGetLanguagesQueryResult = {
  __typename?: 'Query';
  getLanguages:
    | {
        __typename?: 'LanguageResponse';
        languages:
          | Array<
              | {
                  __typename?: 'Language';
                  id: string | null | undefined;
                  code: string | null | undefined;
                  label: string | null | undefined;
                  translation_code: string | null | undefined;
                  languageRegions:
                    | Array<
                        | {
                            __typename?: 'LanguageRegion';
                            code: string | null | undefined;
                            name: string | null | undefined;
                            defaultSpeechApi: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        lastUsedTranscriptionLanguageRegion:
          | {
              __typename?: 'LanguageRegion';
              code: string | null | undefined;
              name: string | null | undefined;
              defaultSpeechApi: string | null | undefined;
              language:
                | {
                    __typename?: 'Language';
                    id: string | null | undefined;
                    code: string | null | undefined;
                    label: string | null | undefined;
                    translation_code: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        lastUsedTranslationLanguagesRegion:
          | Array<
              | {
                  __typename?: 'Language';
                  id: string | null | undefined;
                  code: string | null | undefined;
                  label: string | null | undefined;
                  translation_code: string | null | undefined;
                  languageRegions:
                    | Array<
                        | {
                            __typename?: 'LanguageRegion';
                            code: string | null | undefined;
                            name: string | null | undefined;
                            defaultSpeechApi: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TGetNotificationsQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;

export type TGetNotificationsQueryResult = {
  __typename?: 'Query';
  getNotifications: {
    __typename?: 'NotificationResponse';
    totalUnseen: number;
    listNotification: Array<
      | {
          __typename?: 'Notification';
          id: string;
          user_id: number | null | undefined;
          action_type: NotificationActionType;
          action_description: string | null | undefined;
          creation_date: string | null | undefined;
          is_read: number | null | undefined;
          project_encoded_id: string | null | undefined;
          project_name: string | null | undefined;
          actor:
            | {
                __typename?: 'User';
                id: string;
                full_name: string | null | undefined;
                email: string | null | undefined;
                avatar: string | null | undefined;
                credit: number | null | undefined;
                cardId: string | null | undefined;
                invite_code: string | null | undefined;
                subscription_status: SubscriptionStatusEnum | null | undefined;
                identities: Array<string | null | undefined> | null | undefined;
                usingEmailAuthentication: boolean | null | undefined;
                usingGoogleAuth: boolean | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

export type TGetStatusTranscribeQueryVariables = Exact<{
  jobId?: InputMaybe<Scalars['String']>;
}>;

export type TGetStatusTranscribeQueryResult = {
  __typename?: 'Query';
  getStatusTranscribe: {
    __typename?: 'JobTranscribe';
    id: string | null | undefined;
    mediaId: string | null | undefined;
    status: string | null | undefined;
    statusText: string | null | undefined;
  };
};

export type TGetUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type TGetUserProfileQueryResult = {
  __typename?: 'Query';
  getUserProfile: {
    __typename?: 'User';
    id: string;
    full_name: string | null | undefined;
    email: string | null | undefined;
    avatar: string | null | undefined;
    credit: number | null | undefined;
    cardId: string | null | undefined;
    invite_code: string | null | undefined;
    subscription_status: SubscriptionStatusEnum | null | undefined;
    identities: Array<string | null | undefined> | null | undefined;
    usingEmailAuthentication: boolean | null | undefined;
    usingGoogleAuth: boolean | null | undefined;
    planType:
      | {
          __typename?: 'SimonSaysPlanType';
          id: string;
          label: string | null | undefined;
          plan_enum: string | null | undefined;
          full_label: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type TGetPlanQueryVariables = Exact<{ [key: string]: never }>;

export type TGetPlanQueryResult = {
  __typename?: 'Query';
  getPlan: Array<
    | {
        __typename?: 'PlanType';
        id: string | null | undefined;
        credit_in_minutes: number | null | undefined;
        label: string | null | undefined;
        minimum_charge_in_minutes: number | null | undefined;
        plan_enum: string | null | undefined;
        price_per_hour: number | null | undefined;
        subscription_charge: number | null | undefined;
        active: boolean | null | undefined;
        full_label: string | null | undefined;
        name: string | null | undefined;
        stripe_plan_id: string | null | undefined;
      }
    | null
    | undefined
  >;
};

export type TGetSpeakerQueryVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type TGetSpeakerQueryResult = {
  __typename?: 'Query';
  getSpeaker:
    | Array<
        | { __typename?: 'Speaker'; id: string; name: string | null | undefined; color: string | null | undefined }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type TGetShareProjectQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TGetShareProjectQueryResult = {
  __typename?: 'Query';
  getShareProject: {
    __typename?: 'ShareProjectSocialMedia';
    id: string;
    project_id: string;
    project_name: string | null | undefined;
    description: string | null | undefined;
    gif_preview: string | null | undefined;
    video_preview: string | null | undefined;
    video_status: VideoStatusType | null | undefined;
    img_preview: string | null | undefined;
    share_flatform: ShareFlatformType | null | undefined;
    creation_date: string | null | undefined;
    user:
      | { __typename?: 'UserShareResponse'; full_name: string | null | undefined; avatar: string | null | undefined }
      | null
      | undefined;
  };
};

export type TGetExportQueryVariables = Exact<{
  projectId: Scalars['ID'];
  fileType: Scalars['String'];
  extension: Scalars['String'];
}>;

export type TGetExportQueryResult = {
  __typename?: 'Query';
  getExport:
    | {
        __typename?: 'ExportResponse';
        base64Payload: string | null | undefined;
        url: string | null | undefined;
        fileType: string;
        extension: string;
      }
    | null
    | undefined;
};

export type TGetPaymentLinkQueryVariables = Exact<{ [key: string]: never }>;

export type TGetPaymentLinkQueryResult = { __typename?: 'Query'; getPaymentLink: string };

export type TGetAssemblyProjectPublicDataQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type TGetAssemblyProjectPublicDataQueryResult = {
  __typename?: 'Query';
  getAssemblyProjectPublicData:
    | { __typename?: 'AssemblyProjectPublic'; name: string | null | undefined; thumb: string | null | undefined }
    | null
    | undefined;
};

export type TGetIsOpenListDictQueryVariables = Exact<{ [key: string]: never }>;

export type TGetIsOpenListDictQueryResult = { __typename?: 'Query'; isOpenListDict: boolean | null | undefined };

export type TGetCartItemsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type TGetCartItemsQueryResult = {
  __typename?: 'Query';
  cartItems:
    | Array<
        { __typename?: 'CartItem'; id: string | null | undefined; name: string | null | undefined } | null | undefined
      >
    | null
    | undefined;
};

export type TGetIntercomHashQueryVariables = Exact<{ [key: string]: never }>;

export type TGetIntercomHashQueryResult = { __typename?: 'Query'; getIntercomHash: string | null | undefined };

export type TAssemblyUpdatedSubscriptionVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type TAssemblyUpdatedSubscriptionResult = {
  __typename?: 'Subscription';
  assemblyUpdated: {
    __typename?: 'Assembly';
    id: string;
    version: number | null | undefined;
    segments: Array<{
      __typename?: 'Segment';
      id: string;
      fingerprint: string | null | undefined;
      start: number | null | undefined;
      end: number | null | undefined;
      text: string | null | undefined;
      sort_index: number;
      is_chapter: boolean;
      imported_media:
        | {
            __typename?: 'Media';
            id: string;
            name: string;
            description: string | null | undefined;
            url: string | null | undefined;
            startTimeCode: string | null | undefined;
            fps: number | null | undefined;
            type: string | null | undefined;
            height: number | null | undefined;
            width: number | null | undefined;
            durationInSeconds: number | null | undefined;
            archived: boolean;
            project_folder_id: string | null | undefined;
            status: MediaStatus | null | undefined;
            job_id: string | null | undefined;
            start_time_transcription: string | null | undefined;
            file_upload_type: TypeInputFile | null | undefined;
            project_id: string | null | undefined;
            dictionary_id: string | null | undefined;
            language_region_code: string | null | undefined;
            localClientPathFile: string | null | undefined;
            bookmarksProcessed: boolean;
            muxAssetId: string | null | undefined;
            muxPlaybackId: string | null | undefined;
            percentage: number | null | undefined;
            progressText: string | null | undefined;
          }
        | null
        | undefined;
      selection:
        | {
            __typename?: 'Selection';
            anchorKey: string | null | undefined;
            anchorOffset: number | null | undefined;
            focusKey: string | null | undefined;
            focusOffset: number | null | undefined;
            isBackward: boolean | null | undefined;
            hasFocus: boolean | null | undefined;
            anchorIndex: number | null | undefined;
            focusIndex: number | null | undefined;
          }
        | null
        | undefined;
      annotations: Array<{
        __typename?: 'Annotation';
        id: string;
        creation_date: string;
        last_modification_date: string | null | undefined;
        text: string | null | undefined;
        is_editable: boolean;
        user:
          | {
              __typename?: 'User';
              id: string;
              full_name: string | null | undefined;
              email: string | null | undefined;
              avatar: string | null | undefined;
              credit: number | null | undefined;
              cardId: string | null | undefined;
              invite_code: string | null | undefined;
              subscription_status: SubscriptionStatusEnum | null | undefined;
              identities: Array<string | null | undefined> | null | undefined;
              usingEmailAuthentication: boolean | null | undefined;
              usingGoogleAuth: boolean | null | undefined;
            }
          | null
          | undefined;
      }>;
      reactions: Array<{ __typename?: 'Reaction'; type: ReactionType; is_editable: boolean; creation_date: string }>;
      speaker:
        | { __typename?: 'Speaker'; id: string; name: string | null | undefined; color: string | null | undefined }
        | null
        | undefined;
    }>;
  };
};

export type TActiveUpdatedSubscriptionVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type TActiveUpdatedSubscriptionResult = {
  __typename?: 'Subscription';
  activeUpdated: {
    __typename?: 'ActiveUpdate';
    project_id: string;
    visitors: Array<{
      __typename?: 'Visitor';
      id: string;
      user_id: string | null | undefined;
      full_name: string | null | undefined;
      email: string | null | undefined;
      avatar: string | null | undefined;
      temp_user_id: string | null | undefined;
      active_fingerprint: string | null | undefined;
    }>;
  };
};

export type TProcessUploadFileSubscriptionVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type TProcessUploadFileSubscriptionResult = {
  __typename?: 'Subscription';
  processUploadFile: {
    __typename?: 'PercentageProcessResponse';
    mediaId: string | null | undefined;
    percentageProcessResponse: number | null | undefined;
    errorMessage: string | null | undefined;
  };
};

export type TNotificationAddedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TNotificationAddedSubscriptionResult = {
  __typename?: 'Subscription';
  notificationAdded: {
    __typename?: 'Notification';
    id: string;
    user_id: number | null | undefined;
    action_type: NotificationActionType;
    action_description: string | null | undefined;
    creation_date: string | null | undefined;
    is_read: number | null | undefined;
    project_encoded_id: string | null | undefined;
    project_name: string | null | undefined;
    actor:
      | { __typename?: 'User'; full_name: string | null | undefined; avatar: string | null | undefined }
      | null
      | undefined;
  };
};

export type TProcessTranslateMultipleMediaSubscriptionVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type TProcessTranslateMultipleMediaSubscriptionResult = {
  __typename?: 'Subscription';
  processTranslateMultipleMedia: {
    __typename?: 'ProgressTranslateResponse';
    progress: number | null | undefined;
    mediaIdTranslate: string | null | undefined;
    media:
      | {
          __typename?: 'Media';
          id: string;
          name: string;
          description: string | null | undefined;
          url: string | null | undefined;
          startTimeCode: string | null | undefined;
          fps: number | null | undefined;
          type: string | null | undefined;
          height: number | null | undefined;
          width: number | null | undefined;
          durationInSeconds: number | null | undefined;
          archived: boolean;
          project_folder_id: string | null | undefined;
          status: MediaStatus | null | undefined;
          job_id: string | null | undefined;
          start_time_transcription: string | null | undefined;
          file_upload_type: TypeInputFile | null | undefined;
          project_id: string | null | undefined;
          dictionary_id: string | null | undefined;
          language_region_code: string | null | undefined;
          localClientPathFile: string | null | undefined;
          bookmarksProcessed: boolean;
          muxAssetId: string | null | undefined;
          muxPlaybackId: string | null | undefined;
          percentage: number | null | undefined;
          progressText: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type TOnMediaStatusChangedSubscriptionVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type TOnMediaStatusChangedSubscriptionResult = {
  __typename?: 'Subscription';
  onMediaStatusChanged: {
    __typename?: 'OnTranscribeResponse';
    projectId: string;
    mediaId: string;
    status: MediaStatus;
    transcodingPercentageValue: number | null | undefined;
  };
};

export const CoreAssemblyProjectFieldsFragmentDoc = gql`
  fragment CoreAssemblyProjectFields on AssemblyProject {
    id
    name
    creation_date
    is_owner
    thumb
    height
    width
    timecode
    fps
    last_modification_date
    durationInSeconds
    permission
    my_privilege
    creation_date
    archived
  }
`;
export const CoreMediaFieldsFragmentDoc = gql`
  fragment CoreMediaFields on Media {
    id
    name
    description
    url
    startTimeCode
    fps
    type
    height
    width
    durationInSeconds
    archived
    project_folder_id
    status
    job_id
    start_time_transcription
    file_upload_type
    project_id
    dictionary_id
    language_region_code
    localClientPathFile
    bookmarksProcessed
    muxAssetId
    muxPlaybackId
    percentage @client
    progressText @client
  }
`;
export const CoreTranscriptFieldsFragmentDoc = gql`
  fragment CoreTranscriptFields on TranscriptRow {
    words {
      text
      startTime
      endTime
      style
    }
    speaker {
      id
      name
      color
    }
    annotation
    autoSpeaker
  }
`;
export const CoreMediaWithTranscriptFieldsFragmentDoc = gql`
  fragment CoreMediaWithTranscriptFields on Media {
    ...CoreMediaFields
    transcript {
      ...CoreTranscriptFields
    }
  }
  ${CoreMediaFieldsFragmentDoc}
  ${CoreTranscriptFieldsFragmentDoc}
`;
export const CoreSegmentFieldsFragmentDoc = gql`
  fragment CoreSegmentFields on Segment {
    id
    fingerprint
    start
    end
    text
    sort_index
    is_chapter
  }
`;
export const CoreAssemblyFieldsFragmentDoc = gql`
  fragment CoreAssemblyFields on Assembly {
    id
    version
  }
`;
export const CoreReactionFieldsFragmentDoc = gql`
  fragment CoreReactionFields on Reaction {
    type
    is_editable
    creation_date
  }
`;
export const CoreSelectionFieldsFragmentDoc = gql`
  fragment CoreSelectionFields on Selection {
    anchorKey
    anchorOffset
    focusKey
    focusOffset
    isBackward
    hasFocus
    anchorIndex
    focusIndex
  }
`;
export const CoreAnnotationFieldsFragmentDoc = gql`
  fragment CoreAnnotationFields on Annotation {
    id
    creation_date
    last_modification_date
    text
    is_editable
  }
`;
export const CoreUserFieldsFragmentDoc = gql`
  fragment CoreUserFields on User {
    id
    full_name
    email
    avatar
    credit
    cardId
    invite_code
    subscription_status
    identities
    usingEmailAuthentication
    usingGoogleAuth
  }
`;
export const CorePlanTypeFieldsFragmentDoc = gql`
  fragment CorePlanTypeFields on PlanType {
    id
    credit_in_minutes
    label
    minimum_charge_in_minutes
    plan_enum
    price_per_hour
    subscription_charge
    active
    full_label
    name
    stripe_plan_id
  }
`;
export const CoreSpeakerFieldsFragmentDoc = gql`
  fragment CoreSpeakerFields on Speaker {
    id
    name
    color
  }
`;
export const CoreVisitorFieldsFragmentDoc = gql`
  fragment CoreVisitorFields on Visitor {
    id
    user_id
    full_name
    email
    avatar
    temp_user_id
    active_fingerprint
  }
`;
export const CoreLanguageFieldsFragmentDoc = gql`
  fragment CoreLanguageFields on Language {
    id
    code
    label
    translation_code
  }
`;
export const CoreLanguageRegionFieldsFragmentDoc = gql`
  fragment CoreLanguageRegionFields on LanguageRegion {
    code
    name
    defaultSpeechApi
  }
`;
export const CoreNotificationFieldsFragmentDoc = gql`
  fragment CoreNotificationFields on Notification {
    id
    user_id
    action_type
    action_description
    creation_date
    is_read
    project_encoded_id
    project_name
  }
`;
export const SaveAssemblyDocument = gql`
  mutation saveAssembly($projectId: ID!, $referenceVersion: Int, $segments: [InputSegment!]!) {
    saveAssembly(projectId: $projectId, referenceVersion: $referenceVersion, segments: $segments) {
      id
      version
      segments {
        ...CoreSegmentFields
        imported_media {
          ...CoreMediaFields
        }
        selection {
          ...CoreSelectionFields
        }
        annotations {
          ...CoreAnnotationFields
          user {
            ...CoreUserFields
          }
        }
        reactions {
          ...CoreReactionFields
        }
        speaker {
          id
          name
          color
        }
      }
    }
  }
  ${CoreSegmentFieldsFragmentDoc}
  ${CoreMediaFieldsFragmentDoc}
  ${CoreSelectionFieldsFragmentDoc}
  ${CoreAnnotationFieldsFragmentDoc}
  ${CoreUserFieldsFragmentDoc}
  ${CoreReactionFieldsFragmentDoc}
`;
export type TSaveAssemblyMutationFn = Apollo.MutationFunction<
  TSaveAssemblyMutationResult,
  TSaveAssemblyMutationVariables
>;

/**
 * __useSaveAssemblyMutation__
 *
 * To run a mutation, you first call `useSaveAssemblyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssemblyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssemblyMutation, { data, loading, error }] = useSaveAssemblyMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      referenceVersion: // value for 'referenceVersion'
 *      segments: // value for 'segments'
 *   },
 * });
 */
export function useSaveAssemblyMutation(
  baseOptions?: Apollo.MutationHookOptions<TSaveAssemblyMutationResult, TSaveAssemblyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSaveAssemblyMutationResult, TSaveAssemblyMutationVariables>(SaveAssemblyDocument, options);
}
export type SaveAssemblyMutationHookResult = ReturnType<typeof useSaveAssemblyMutation>;
export type SaveAssemblyMutationResult = Apollo.MutationResult<TSaveAssemblyMutationResult>;
export type SaveAssemblyMutationOptions = Apollo.BaseMutationOptions<
  TSaveAssemblyMutationResult,
  TSaveAssemblyMutationVariables
>;
export const CreateNewAssemblyProjectDocument = gql`
  mutation createNewAssemblyProject($name: String) {
    createNewAssemblyProject(name: $name) {
      id
    }
  }
`;
export type TCreateNewAssemblyProjectMutationFn = Apollo.MutationFunction<
  TCreateNewAssemblyProjectMutationResult,
  TCreateNewAssemblyProjectMutationVariables
>;

/**
 * __useCreateNewAssemblyProjectMutation__
 *
 * To run a mutation, you first call `useCreateNewAssemblyProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewAssemblyProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewAssemblyProjectMutation, { data, loading, error }] = useCreateNewAssemblyProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateNewAssemblyProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TCreateNewAssemblyProjectMutationResult,
    TCreateNewAssemblyProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateNewAssemblyProjectMutationResult, TCreateNewAssemblyProjectMutationVariables>(
    CreateNewAssemblyProjectDocument,
    options,
  );
}
export type CreateNewAssemblyProjectMutationHookResult = ReturnType<typeof useCreateNewAssemblyProjectMutation>;
export type CreateNewAssemblyProjectMutationResult = Apollo.MutationResult<TCreateNewAssemblyProjectMutationResult>;
export type CreateNewAssemblyProjectMutationOptions = Apollo.BaseMutationOptions<
  TCreateNewAssemblyProjectMutationResult,
  TCreateNewAssemblyProjectMutationVariables
>;
export const CreateMediaFolderInProjectDocument = gql`
  mutation createMediaFolderInProject($name: String!, $projectId: ID!) {
    createMediaFolderInProject(name: $name, projectId: $projectId) {
      id
      folders {
        id
        name
      }
    }
  }
`;
export type TCreateMediaFolderInProjectMutationFn = Apollo.MutationFunction<
  TCreateMediaFolderInProjectMutationResult,
  TCreateMediaFolderInProjectMutationVariables
>;

/**
 * __useCreateMediaFolderInProjectMutation__
 *
 * To run a mutation, you first call `useCreateMediaFolderInProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaFolderInProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaFolderInProjectMutation, { data, loading, error }] = useCreateMediaFolderInProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateMediaFolderInProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TCreateMediaFolderInProjectMutationResult,
    TCreateMediaFolderInProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateMediaFolderInProjectMutationResult, TCreateMediaFolderInProjectMutationVariables>(
    CreateMediaFolderInProjectDocument,
    options,
  );
}
export type CreateMediaFolderInProjectMutationHookResult = ReturnType<typeof useCreateMediaFolderInProjectMutation>;
export type CreateMediaFolderInProjectMutationResult = Apollo.MutationResult<TCreateMediaFolderInProjectMutationResult>;
export type CreateMediaFolderInProjectMutationOptions = Apollo.BaseMutationOptions<
  TCreateMediaFolderInProjectMutationResult,
  TCreateMediaFolderInProjectMutationVariables
>;
export const RenameAssemblyProjectDocument = gql`
  mutation renameAssemblyProject($projectId: ID!, $name: String!) {
    renameAssemblyProject(projectId: $projectId, name: $name) {
      id
      name
    }
  }
`;
export type TRenameAssemblyProjectMutationFn = Apollo.MutationFunction<
  TRenameAssemblyProjectMutationResult,
  TRenameAssemblyProjectMutationVariables
>;

/**
 * __useRenameAssemblyProjectMutation__
 *
 * To run a mutation, you first call `useRenameAssemblyProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAssemblyProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAssemblyProjectMutation, { data, loading, error }] = useRenameAssemblyProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameAssemblyProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TRenameAssemblyProjectMutationResult,
    TRenameAssemblyProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TRenameAssemblyProjectMutationResult, TRenameAssemblyProjectMutationVariables>(
    RenameAssemblyProjectDocument,
    options,
  );
}
export type RenameAssemblyProjectMutationHookResult = ReturnType<typeof useRenameAssemblyProjectMutation>;
export type RenameAssemblyProjectMutationResult = Apollo.MutationResult<TRenameAssemblyProjectMutationResult>;
export type RenameAssemblyProjectMutationOptions = Apollo.BaseMutationOptions<
  TRenameAssemblyProjectMutationResult,
  TRenameAssemblyProjectMutationVariables
>;
export const AddPermissionForEmailsDocument = gql`
  mutation addPermissionForEmails($projectId: ID!, $emails: [String!]!, $privilege: Permission!) {
    addPermissionForEmails(projectId: $projectId, emails: $emails, privilege: $privilege) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;
export type TAddPermissionForEmailsMutationFn = Apollo.MutationFunction<
  TAddPermissionForEmailsMutationResult,
  TAddPermissionForEmailsMutationVariables
>;

/**
 * __useAddPermissionForEmailsMutation__
 *
 * To run a mutation, you first call `useAddPermissionForEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPermissionForEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPermissionForEmailsMutation, { data, loading, error }] = useAddPermissionForEmailsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      emails: // value for 'emails'
 *      privilege: // value for 'privilege'
 *   },
 * });
 */
export function useAddPermissionForEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TAddPermissionForEmailsMutationResult,
    TAddPermissionForEmailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAddPermissionForEmailsMutationResult, TAddPermissionForEmailsMutationVariables>(
    AddPermissionForEmailsDocument,
    options,
  );
}
export type AddPermissionForEmailsMutationHookResult = ReturnType<typeof useAddPermissionForEmailsMutation>;
export type AddPermissionForEmailsMutationResult = Apollo.MutationResult<TAddPermissionForEmailsMutationResult>;
export type AddPermissionForEmailsMutationOptions = Apollo.BaseMutationOptions<
  TAddPermissionForEmailsMutationResult,
  TAddPermissionForEmailsMutationVariables
>;
export const ChangeAssemblyProjectPermissionDocument = gql`
  mutation changeAssemblyProjectPermission($projectId: ID!, $permission: Permission!) {
    changeAssemblyProjectPermission(projectId: $projectId, permission: $permission) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;
export type TChangeAssemblyProjectPermissionMutationFn = Apollo.MutationFunction<
  TChangeAssemblyProjectPermissionMutationResult,
  TChangeAssemblyProjectPermissionMutationVariables
>;

/**
 * __useChangeAssemblyProjectPermissionMutation__
 *
 * To run a mutation, you first call `useChangeAssemblyProjectPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAssemblyProjectPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAssemblyProjectPermissionMutation, { data, loading, error }] = useChangeAssemblyProjectPermissionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useChangeAssemblyProjectPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TChangeAssemblyProjectPermissionMutationResult,
    TChangeAssemblyProjectPermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TChangeAssemblyProjectPermissionMutationResult,
    TChangeAssemblyProjectPermissionMutationVariables
  >(ChangeAssemblyProjectPermissionDocument, options);
}
export type ChangeAssemblyProjectPermissionMutationHookResult = ReturnType<
  typeof useChangeAssemblyProjectPermissionMutation
>;
export type ChangeAssemblyProjectPermissionMutationResult =
  Apollo.MutationResult<TChangeAssemblyProjectPermissionMutationResult>;
export type ChangeAssemblyProjectPermissionMutationOptions = Apollo.BaseMutationOptions<
  TChangeAssemblyProjectPermissionMutationResult,
  TChangeAssemblyProjectPermissionMutationVariables
>;
export const AmendPermissionForEmailDocument = gql`
  mutation amendPermissionForEmail($projectId: ID!, $email: String!, $privilege: Permission!) {
    amendPermissionForEmail(projectId: $projectId, email: $email, privilege: $privilege) {
      id
      shares {
        id
        invitee
        privilege
      }
      permission
    }
  }
`;
export type TAmendPermissionForEmailMutationFn = Apollo.MutationFunction<
  TAmendPermissionForEmailMutationResult,
  TAmendPermissionForEmailMutationVariables
>;

/**
 * __useAmendPermissionForEmailMutation__
 *
 * To run a mutation, you first call `useAmendPermissionForEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendPermissionForEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendPermissionForEmailMutation, { data, loading, error }] = useAmendPermissionForEmailMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      email: // value for 'email'
 *      privilege: // value for 'privilege'
 *   },
 * });
 */
export function useAmendPermissionForEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TAmendPermissionForEmailMutationResult,
    TAmendPermissionForEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAmendPermissionForEmailMutationResult, TAmendPermissionForEmailMutationVariables>(
    AmendPermissionForEmailDocument,
    options,
  );
}
export type AmendPermissionForEmailMutationHookResult = ReturnType<typeof useAmendPermissionForEmailMutation>;
export type AmendPermissionForEmailMutationResult = Apollo.MutationResult<TAmendPermissionForEmailMutationResult>;
export type AmendPermissionForEmailMutationOptions = Apollo.BaseMutationOptions<
  TAmendPermissionForEmailMutationResult,
  TAmendPermissionForEmailMutationVariables
>;
export const AmendMediaDocument = gql`
  mutation amendMedia($mediaId: ID!, $folderId: ID) {
    amendMedia(mediaId: $mediaId, folderId: $folderId) {
      id
      folders {
        id
        name
      }
    }
  }
`;
export type TAmendMediaMutationFn = Apollo.MutationFunction<TAmendMediaMutationResult, TAmendMediaMutationVariables>;

/**
 * __useAmendMediaMutation__
 *
 * To run a mutation, you first call `useAmendMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendMediaMutation, { data, loading, error }] = useAmendMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useAmendMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<TAmendMediaMutationResult, TAmendMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAmendMediaMutationResult, TAmendMediaMutationVariables>(AmendMediaDocument, options);
}
export type AmendMediaMutationHookResult = ReturnType<typeof useAmendMediaMutation>;
export type AmendMediaMutationResult = Apollo.MutationResult<TAmendMediaMutationResult>;
export type AmendMediaMutationOptions = Apollo.BaseMutationOptions<
  TAmendMediaMutationResult,
  TAmendMediaMutationVariables
>;
export const AmendMediaFolderDocument = gql`
  mutation amendMediaFolder($folderId: ID!, $name: String!) {
    amendMediaFolder(folderId: $folderId, name: $name) {
      id
    }
  }
`;
export type TAmendMediaFolderMutationFn = Apollo.MutationFunction<
  TAmendMediaFolderMutationResult,
  TAmendMediaFolderMutationVariables
>;

/**
 * __useAmendMediaFolderMutation__
 *
 * To run a mutation, you first call `useAmendMediaFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendMediaFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendMediaFolderMutation, { data, loading, error }] = useAmendMediaFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAmendMediaFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<TAmendMediaFolderMutationResult, TAmendMediaFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAmendMediaFolderMutationResult, TAmendMediaFolderMutationVariables>(
    AmendMediaFolderDocument,
    options,
  );
}
export type AmendMediaFolderMutationHookResult = ReturnType<typeof useAmendMediaFolderMutation>;
export type AmendMediaFolderMutationResult = Apollo.MutationResult<TAmendMediaFolderMutationResult>;
export type AmendMediaFolderMutationOptions = Apollo.BaseMutationOptions<
  TAmendMediaFolderMutationResult,
  TAmendMediaFolderMutationVariables
>;
export const CreateAnnotationDocument = gql`
  mutation createAnnotation($segmentFingerprint: ID!, $text: String!) {
    createAnnotation(segmentFingerprint: $segmentFingerprint, text: $text) {
      id
      version
      segments {
        id
        annotations {
          id
          user {
            user_id
            full_name
            email
            avatar
          }
          creation_date
          last_modification_date
          text
          is_editable
        }
      }
    }
  }
`;
export type TCreateAnnotationMutationFn = Apollo.MutationFunction<
  TCreateAnnotationMutationResult,
  TCreateAnnotationMutationVariables
>;

/**
 * __useCreateAnnotationMutation__
 *
 * To run a mutation, you first call `useCreateAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnotationMutation, { data, loading, error }] = useCreateAnnotationMutation({
 *   variables: {
 *      segmentFingerprint: // value for 'segmentFingerprint'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateAnnotationMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateAnnotationMutationResult, TCreateAnnotationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateAnnotationMutationResult, TCreateAnnotationMutationVariables>(
    CreateAnnotationDocument,
    options,
  );
}
export type CreateAnnotationMutationHookResult = ReturnType<typeof useCreateAnnotationMutation>;
export type CreateAnnotationMutationResult = Apollo.MutationResult<TCreateAnnotationMutationResult>;
export type CreateAnnotationMutationOptions = Apollo.BaseMutationOptions<
  TCreateAnnotationMutationResult,
  TCreateAnnotationMutationVariables
>;
export const AmendAssemblyProjectDocument = gql`
  mutation amendAssemblyProject($projectId: ID!, $width: Int, $height: Int, $fps: String, $timecode: String) {
    amendAssemblyProject(projectId: $projectId, width: $width, height: $height, fps: $fps, timecode: $timecode) {
      id
    }
  }
`;
export type TAmendAssemblyProjectMutationFn = Apollo.MutationFunction<
  TAmendAssemblyProjectMutationResult,
  TAmendAssemblyProjectMutationVariables
>;

/**
 * __useAmendAssemblyProjectMutation__
 *
 * To run a mutation, you first call `useAmendAssemblyProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendAssemblyProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendAssemblyProjectMutation, { data, loading, error }] = useAmendAssemblyProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      fps: // value for 'fps'
 *      timecode: // value for 'timecode'
 *   },
 * });
 */
export function useAmendAssemblyProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<TAmendAssemblyProjectMutationResult, TAmendAssemblyProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAmendAssemblyProjectMutationResult, TAmendAssemblyProjectMutationVariables>(
    AmendAssemblyProjectDocument,
    options,
  );
}
export type AmendAssemblyProjectMutationHookResult = ReturnType<typeof useAmendAssemblyProjectMutation>;
export type AmendAssemblyProjectMutationResult = Apollo.MutationResult<TAmendAssemblyProjectMutationResult>;
export type AmendAssemblyProjectMutationOptions = Apollo.BaseMutationOptions<
  TAmendAssemblyProjectMutationResult,
  TAmendAssemblyProjectMutationVariables
>;
export const AmendAnnotationDocument = gql`
  mutation amendAnnotation($annotationId: ID!, $text: String!) {
    amendAnnotation(annotationId: $annotationId, text: $text) {
      id
      version
      segments {
        id
        annotations {
          id
          creation_date
          last_modification_date
          text
          is_editable
          user {
            user_id
            full_name
            email
            avatar
          }
        }
      }
    }
  }
`;
export type TAmendAnnotationMutationFn = Apollo.MutationFunction<
  TAmendAnnotationMutationResult,
  TAmendAnnotationMutationVariables
>;

/**
 * __useAmendAnnotationMutation__
 *
 * To run a mutation, you first call `useAmendAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendAnnotationMutation, { data, loading, error }] = useAmendAnnotationMutation({
 *   variables: {
 *      annotationId: // value for 'annotationId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAmendAnnotationMutation(
  baseOptions?: Apollo.MutationHookOptions<TAmendAnnotationMutationResult, TAmendAnnotationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAmendAnnotationMutationResult, TAmendAnnotationMutationVariables>(
    AmendAnnotationDocument,
    options,
  );
}
export type AmendAnnotationMutationHookResult = ReturnType<typeof useAmendAnnotationMutation>;
export type AmendAnnotationMutationResult = Apollo.MutationResult<TAmendAnnotationMutationResult>;
export type AmendAnnotationMutationOptions = Apollo.BaseMutationOptions<
  TAmendAnnotationMutationResult,
  TAmendAnnotationMutationVariables
>;
export const AmendReactionDocument = gql`
  mutation amendReaction($segmentFingerprint: ID!, $type: ReactionType!, $remove: Boolean) {
    amendReaction(segmentFingerprint: $segmentFingerprint, type: $type, remove: $remove) {
      id
      version
      segments {
        id
        reactions {
          type
          is_editable
        }
      }
    }
  }
`;
export type TAmendReactionMutationFn = Apollo.MutationFunction<
  TAmendReactionMutationResult,
  TAmendReactionMutationVariables
>;

/**
 * __useAmendReactionMutation__
 *
 * To run a mutation, you first call `useAmendReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendReactionMutation, { data, loading, error }] = useAmendReactionMutation({
 *   variables: {
 *      segmentFingerprint: // value for 'segmentFingerprint'
 *      type: // value for 'type'
 *      remove: // value for 'remove'
 *   },
 * });
 */
export function useAmendReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<TAmendReactionMutationResult, TAmendReactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAmendReactionMutationResult, TAmendReactionMutationVariables>(
    AmendReactionDocument,
    options,
  );
}
export type AmendReactionMutationHookResult = ReturnType<typeof useAmendReactionMutation>;
export type AmendReactionMutationResult = Apollo.MutationResult<TAmendReactionMutationResult>;
export type AmendReactionMutationOptions = Apollo.BaseMutationOptions<
  TAmendReactionMutationResult,
  TAmendReactionMutationVariables
>;
export const MarkMediaBookmarksAsProcessedDocument = gql`
  mutation markMediaBookmarksAsProcessed($mediaId: ID!) {
    markMediaBookmarksAsProcessed(mediaId: $mediaId) {
      id
      name
      url
      startTimeCode
      fps
      localClientPathFile
      bookmarksProcessed
      transcript {
        words {
          text
          startTime
          endTime
          style
        }
        speaker {
          id
          name
          color
        }
        annotation
      }
    }
  }
`;
export type TMarkMediaBookmarksAsProcessedMutationFn = Apollo.MutationFunction<
  TMarkMediaBookmarksAsProcessedMutationResult,
  TMarkMediaBookmarksAsProcessedMutationVariables
>;

/**
 * __useMarkMediaBookmarksAsProcessedMutation__
 *
 * To run a mutation, you first call `useMarkMediaBookmarksAsProcessedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMediaBookmarksAsProcessedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMediaBookmarksAsProcessedMutation, { data, loading, error }] = useMarkMediaBookmarksAsProcessedMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMarkMediaBookmarksAsProcessedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TMarkMediaBookmarksAsProcessedMutationResult,
    TMarkMediaBookmarksAsProcessedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TMarkMediaBookmarksAsProcessedMutationResult,
    TMarkMediaBookmarksAsProcessedMutationVariables
  >(MarkMediaBookmarksAsProcessedDocument, options);
}
export type MarkMediaBookmarksAsProcessedMutationHookResult = ReturnType<
  typeof useMarkMediaBookmarksAsProcessedMutation
>;
export type MarkMediaBookmarksAsProcessedMutationResult =
  Apollo.MutationResult<TMarkMediaBookmarksAsProcessedMutationResult>;
export type MarkMediaBookmarksAsProcessedMutationOptions = Apollo.BaseMutationOptions<
  TMarkMediaBookmarksAsProcessedMutationResult,
  TMarkMediaBookmarksAsProcessedMutationVariables
>;
export const ArchiveAssemblyProjectDocument = gql`
  mutation archiveAssemblyProject($projectId: ID!, $archive: Boolean!) {
    archiveAssemblyProject(projectId: $projectId, archive: $archive) {
      id
      name
      archived
    }
  }
`;
export type TArchiveAssemblyProjectMutationFn = Apollo.MutationFunction<
  TArchiveAssemblyProjectMutationResult,
  TArchiveAssemblyProjectMutationVariables
>;

/**
 * __useArchiveAssemblyProjectMutation__
 *
 * To run a mutation, you first call `useArchiveAssemblyProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAssemblyProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAssemblyProjectMutation, { data, loading, error }] = useArchiveAssemblyProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useArchiveAssemblyProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TArchiveAssemblyProjectMutationResult,
    TArchiveAssemblyProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TArchiveAssemblyProjectMutationResult, TArchiveAssemblyProjectMutationVariables>(
    ArchiveAssemblyProjectDocument,
    options,
  );
}
export type ArchiveAssemblyProjectMutationHookResult = ReturnType<typeof useArchiveAssemblyProjectMutation>;
export type ArchiveAssemblyProjectMutationResult = Apollo.MutationResult<TArchiveAssemblyProjectMutationResult>;
export type ArchiveAssemblyProjectMutationOptions = Apollo.BaseMutationOptions<
  TArchiveAssemblyProjectMutationResult,
  TArchiveAssemblyProjectMutationVariables
>;
export const ArchiveMediaInAssemblyProjectDocument = gql`
  mutation archiveMediaInAssemblyProject($projectId: ID!, $mediaId: ID!) {
    archiveMediaInAssemblyProject(projectId: $projectId, mediaId: $mediaId) {
      id
      imported_media {
        id
        name
        url
        startTimeCode
        fps
        type
        height
        width
        durationInSeconds
        type
        archived
        project_folder_id
        project_id
        status
        job_id
        start_time_transcription
        file_upload_type
        dictionary_id
        language_region_code
      }
    }
  }
`;
export type TArchiveMediaInAssemblyProjectMutationFn = Apollo.MutationFunction<
  TArchiveMediaInAssemblyProjectMutationResult,
  TArchiveMediaInAssemblyProjectMutationVariables
>;

/**
 * __useArchiveMediaInAssemblyProjectMutation__
 *
 * To run a mutation, you first call `useArchiveMediaInAssemblyProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMediaInAssemblyProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMediaInAssemblyProjectMutation, { data, loading, error }] = useArchiveMediaInAssemblyProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useArchiveMediaInAssemblyProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TArchiveMediaInAssemblyProjectMutationResult,
    TArchiveMediaInAssemblyProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TArchiveMediaInAssemblyProjectMutationResult,
    TArchiveMediaInAssemblyProjectMutationVariables
  >(ArchiveMediaInAssemblyProjectDocument, options);
}
export type ArchiveMediaInAssemblyProjectMutationHookResult = ReturnType<
  typeof useArchiveMediaInAssemblyProjectMutation
>;
export type ArchiveMediaInAssemblyProjectMutationResult =
  Apollo.MutationResult<TArchiveMediaInAssemblyProjectMutationResult>;
export type ArchiveMediaInAssemblyProjectMutationOptions = Apollo.BaseMutationOptions<
  TArchiveMediaInAssemblyProjectMutationResult,
  TArchiveMediaInAssemblyProjectMutationVariables
>;
export const ArchiveMediaFolderDocument = gql`
  mutation archiveMediaFolder($folderId: ID!, $archive: Boolean) {
    archiveMediaFolder(folderId: $folderId, archive: $archive) {
      id
    }
  }
`;
export type TArchiveMediaFolderMutationFn = Apollo.MutationFunction<
  TArchiveMediaFolderMutationResult,
  TArchiveMediaFolderMutationVariables
>;

/**
 * __useArchiveMediaFolderMutation__
 *
 * To run a mutation, you first call `useArchiveMediaFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMediaFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMediaFolderMutation, { data, loading, error }] = useArchiveMediaFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useArchiveMediaFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<TArchiveMediaFolderMutationResult, TArchiveMediaFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TArchiveMediaFolderMutationResult, TArchiveMediaFolderMutationVariables>(
    ArchiveMediaFolderDocument,
    options,
  );
}
export type ArchiveMediaFolderMutationHookResult = ReturnType<typeof useArchiveMediaFolderMutation>;
export type ArchiveMediaFolderMutationResult = Apollo.MutationResult<TArchiveMediaFolderMutationResult>;
export type ArchiveMediaFolderMutationOptions = Apollo.BaseMutationOptions<
  TArchiveMediaFolderMutationResult,
  TArchiveMediaFolderMutationVariables
>;
export const CreateMediaDocument = gql`
  mutation createMedia($filename: String!, $frameFilePath: String!, $projectId: ID!, $type: TypeInputFile) {
    createMedia(filename: $filename, frameFilePath: $frameFilePath, projectId: $projectId, type: $type) {
      ...CoreMediaFields
    }
  }
  ${CoreMediaFieldsFragmentDoc}
`;
export type TCreateMediaMutationFn = Apollo.MutationFunction<TCreateMediaMutationResult, TCreateMediaMutationVariables>;

/**
 * __useCreateMediaMutation__
 *
 * To run a mutation, you first call `useCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaMutation, { data, loading, error }] = useCreateMediaMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      frameFilePath: // value for 'frameFilePath'
 *      projectId: // value for 'projectId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateMediaMutationResult, TCreateMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateMediaMutationResult, TCreateMediaMutationVariables>(CreateMediaDocument, options);
}
export type CreateMediaMutationHookResult = ReturnType<typeof useCreateMediaMutation>;
export type CreateMediaMutationResult = Apollo.MutationResult<TCreateMediaMutationResult>;
export type CreateMediaMutationOptions = Apollo.BaseMutationOptions<
  TCreateMediaMutationResult,
  TCreateMediaMutationVariables
>;
export const CreateSignUrlAwsDocument = gql`
  mutation createSignUrlAws($filename: String!, $mimeType: String!, $mediaId: ID!) {
    createSignUrlAws(filename: $filename, mimeType: $mimeType, mediaId: $mediaId) {
      key
      url
      mediaId
    }
  }
`;
export type TCreateSignUrlAwsMutationFn = Apollo.MutationFunction<
  TCreateSignUrlAwsMutationResult,
  TCreateSignUrlAwsMutationVariables
>;

/**
 * __useCreateSignUrlAwsMutation__
 *
 * To run a mutation, you first call `useCreateSignUrlAwsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignUrlAwsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignUrlAwsMutation, { data, loading, error }] = useCreateSignUrlAwsMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      mimeType: // value for 'mimeType'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useCreateSignUrlAwsMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateSignUrlAwsMutationResult, TCreateSignUrlAwsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateSignUrlAwsMutationResult, TCreateSignUrlAwsMutationVariables>(
    CreateSignUrlAwsDocument,
    options,
  );
}
export type CreateSignUrlAwsMutationHookResult = ReturnType<typeof useCreateSignUrlAwsMutation>;
export type CreateSignUrlAwsMutationResult = Apollo.MutationResult<TCreateSignUrlAwsMutationResult>;
export type CreateSignUrlAwsMutationOptions = Apollo.BaseMutationOptions<
  TCreateSignUrlAwsMutationResult,
  TCreateSignUrlAwsMutationVariables
>;
export const CreateMultipartPreSignedUrlsDocument = gql`
  mutation createMultipartPreSignedUrls($filename: String!, $mimeType: String!, $mediaId: ID!, $parts: Int!) {
    createMultipartPreSignedUrls(filename: $filename, mimeType: $mimeType, mediaId: $mediaId, parts: $parts) {
      fileId
      fileKey
      parts {
        partNumber
        signedUrl
      }
    }
  }
`;
export type TCreateMultipartPreSignedUrlsMutationFn = Apollo.MutationFunction<
  TCreateMultipartPreSignedUrlsMutationResult,
  TCreateMultipartPreSignedUrlsMutationVariables
>;

/**
 * __useCreateMultipartPreSignedUrlsMutation__
 *
 * To run a mutation, you first call `useCreateMultipartPreSignedUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipartPreSignedUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipartPreSignedUrlsMutation, { data, loading, error }] = useCreateMultipartPreSignedUrlsMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      mimeType: // value for 'mimeType'
 *      mediaId: // value for 'mediaId'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useCreateMultipartPreSignedUrlsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TCreateMultipartPreSignedUrlsMutationResult,
    TCreateMultipartPreSignedUrlsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TCreateMultipartPreSignedUrlsMutationResult,
    TCreateMultipartPreSignedUrlsMutationVariables
  >(CreateMultipartPreSignedUrlsDocument, options);
}
export type CreateMultipartPreSignedUrlsMutationHookResult = ReturnType<typeof useCreateMultipartPreSignedUrlsMutation>;
export type CreateMultipartPreSignedUrlsMutationResult =
  Apollo.MutationResult<TCreateMultipartPreSignedUrlsMutationResult>;
export type CreateMultipartPreSignedUrlsMutationOptions = Apollo.BaseMutationOptions<
  TCreateMultipartPreSignedUrlsMutationResult,
  TCreateMultipartPreSignedUrlsMutationVariables
>;
export const FinalizeMultipartUploadDocument = gql`
  mutation finalizeMultipartUpload($input: finalizeMultipartUploadInput!) {
    finalizeMultipartUpload(input: $input) {
      url
      key
    }
  }
`;
export type TFinalizeMultipartUploadMutationFn = Apollo.MutationFunction<
  TFinalizeMultipartUploadMutationResult,
  TFinalizeMultipartUploadMutationVariables
>;

/**
 * __useFinalizeMultipartUploadMutation__
 *
 * To run a mutation, you first call `useFinalizeMultipartUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeMultipartUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeMultipartUploadMutation, { data, loading, error }] = useFinalizeMultipartUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeMultipartUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TFinalizeMultipartUploadMutationResult,
    TFinalizeMultipartUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TFinalizeMultipartUploadMutationResult, TFinalizeMultipartUploadMutationVariables>(
    FinalizeMultipartUploadDocument,
    options,
  );
}
export type FinalizeMultipartUploadMutationHookResult = ReturnType<typeof useFinalizeMultipartUploadMutation>;
export type FinalizeMultipartUploadMutationResult = Apollo.MutationResult<TFinalizeMultipartUploadMutationResult>;
export type FinalizeMultipartUploadMutationOptions = Apollo.BaseMutationOptions<
  TFinalizeMultipartUploadMutationResult,
  TFinalizeMultipartUploadMutationVariables
>;
export const CloneAssemblyProjectDocument = gql`
  mutation cloneAssemblyProject($projectId: ID!) {
    cloneAssemblyProject(projectId: $projectId) {
      id
    }
  }
`;
export type TCloneAssemblyProjectMutationFn = Apollo.MutationFunction<
  TCloneAssemblyProjectMutationResult,
  TCloneAssemblyProjectMutationVariables
>;

/**
 * __useCloneAssemblyProjectMutation__
 *
 * To run a mutation, you first call `useCloneAssemblyProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneAssemblyProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneAssemblyProjectMutation, { data, loading, error }] = useCloneAssemblyProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCloneAssemblyProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<TCloneAssemblyProjectMutationResult, TCloneAssemblyProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCloneAssemblyProjectMutationResult, TCloneAssemblyProjectMutationVariables>(
    CloneAssemblyProjectDocument,
    options,
  );
}
export type CloneAssemblyProjectMutationHookResult = ReturnType<typeof useCloneAssemblyProjectMutation>;
export type CloneAssemblyProjectMutationResult = Apollo.MutationResult<TCloneAssemblyProjectMutationResult>;
export type CloneAssemblyProjectMutationOptions = Apollo.BaseMutationOptions<
  TCloneAssemblyProjectMutationResult,
  TCloneAssemblyProjectMutationVariables
>;
export const UploadImportMediaIntoProjectDocument = gql`
  mutation uploadImportMediaIntoProject(
    $mediaId: ID!
    $fileName: String!
    $type: TypeInputFile!
    $linkToFile: String
    $accessToken: String
    $fileSize: String
    $durationInSeconds: Float
  ) {
    uploadImportMediaIntoProject(
      mediaId: $mediaId
      fileName: $fileName
      type: $type
      linkToFile: $linkToFile
      accessToken: $accessToken
      fileSize: $fileSize
      durationInSeconds: $durationInSeconds
    ) {
      ...CoreMediaFields
    }
  }
  ${CoreMediaFieldsFragmentDoc}
`;
export type TUploadImportMediaIntoProjectMutationFn = Apollo.MutationFunction<
  TUploadImportMediaIntoProjectMutationResult,
  TUploadImportMediaIntoProjectMutationVariables
>;

/**
 * __useUploadImportMediaIntoProjectMutation__
 *
 * To run a mutation, you first call `useUploadImportMediaIntoProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImportMediaIntoProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImportMediaIntoProjectMutation, { data, loading, error }] = useUploadImportMediaIntoProjectMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      fileName: // value for 'fileName'
 *      type: // value for 'type'
 *      linkToFile: // value for 'linkToFile'
 *      accessToken: // value for 'accessToken'
 *      fileSize: // value for 'fileSize'
 *      durationInSeconds: // value for 'durationInSeconds'
 *   },
 * });
 */
export function useUploadImportMediaIntoProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUploadImportMediaIntoProjectMutationResult,
    TUploadImportMediaIntoProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TUploadImportMediaIntoProjectMutationResult,
    TUploadImportMediaIntoProjectMutationVariables
  >(UploadImportMediaIntoProjectDocument, options);
}
export type UploadImportMediaIntoProjectMutationHookResult = ReturnType<typeof useUploadImportMediaIntoProjectMutation>;
export type UploadImportMediaIntoProjectMutationResult =
  Apollo.MutationResult<TUploadImportMediaIntoProjectMutationResult>;
export type UploadImportMediaIntoProjectMutationOptions = Apollo.BaseMutationOptions<
  TUploadImportMediaIntoProjectMutationResult,
  TUploadImportMediaIntoProjectMutationVariables
>;
export const NotifySeverMediaUploadToMuxDocument = gql`
  mutation notifySeverMediaUploadToMux(
    $mediaId: ID!
    $fileName: String!
    $type: TypeInputFile!
    $linkToFile: String
    $accessToken: String
    $fileSize: String
    $durationInSeconds: Float
  ) {
    notifySeverMediaUploadToMux(
      mediaId: $mediaId
      fileName: $fileName
      type: $type
      linkToFile: $linkToFile
      accessToken: $accessToken
      fileSize: $fileSize
      durationInSeconds: $durationInSeconds
    ) {
      ...CoreMediaFields
    }
  }
  ${CoreMediaFieldsFragmentDoc}
`;
export type TNotifySeverMediaUploadToMuxMutationFn = Apollo.MutationFunction<
  TNotifySeverMediaUploadToMuxMutationResult,
  TNotifySeverMediaUploadToMuxMutationVariables
>;

/**
 * __useNotifySeverMediaUploadToMuxMutation__
 *
 * To run a mutation, you first call `useNotifySeverMediaUploadToMuxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifySeverMediaUploadToMuxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifySeverMediaUploadToMuxMutation, { data, loading, error }] = useNotifySeverMediaUploadToMuxMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      fileName: // value for 'fileName'
 *      type: // value for 'type'
 *      linkToFile: // value for 'linkToFile'
 *      accessToken: // value for 'accessToken'
 *      fileSize: // value for 'fileSize'
 *      durationInSeconds: // value for 'durationInSeconds'
 *   },
 * });
 */
export function useNotifySeverMediaUploadToMuxMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TNotifySeverMediaUploadToMuxMutationResult,
    TNotifySeverMediaUploadToMuxMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TNotifySeverMediaUploadToMuxMutationResult, TNotifySeverMediaUploadToMuxMutationVariables>(
    NotifySeverMediaUploadToMuxDocument,
    options,
  );
}
export type NotifySeverMediaUploadToMuxMutationHookResult = ReturnType<typeof useNotifySeverMediaUploadToMuxMutation>;
export type NotifySeverMediaUploadToMuxMutationResult =
  Apollo.MutationResult<TNotifySeverMediaUploadToMuxMutationResult>;
export type NotifySeverMediaUploadToMuxMutationOptions = Apollo.BaseMutationOptions<
  TNotifySeverMediaUploadToMuxMutationResult,
  TNotifySeverMediaUploadToMuxMutationVariables
>;
export const TranscribeMediaDocument = gql`
  mutation transcribeMedia($mediaId: ID!, $language: String!, $defaultSpeechApi: String!) {
    transcribeMedia(mediaId: $mediaId, language: $language, defaultSpeechApi: $defaultSpeechApi) {
      id
      mediaId
      status
      statusText
    }
  }
`;
export type TTranscribeMediaMutationFn = Apollo.MutationFunction<
  TTranscribeMediaMutationResult,
  TTranscribeMediaMutationVariables
>;

/**
 * __useTranscribeMediaMutation__
 *
 * To run a mutation, you first call `useTranscribeMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranscribeMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transcribeMediaMutation, { data, loading, error }] = useTranscribeMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      language: // value for 'language'
 *      defaultSpeechApi: // value for 'defaultSpeechApi'
 *   },
 * });
 */
export function useTranscribeMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<TTranscribeMediaMutationResult, TTranscribeMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TTranscribeMediaMutationResult, TTranscribeMediaMutationVariables>(
    TranscribeMediaDocument,
    options,
  );
}
export type TranscribeMediaMutationHookResult = ReturnType<typeof useTranscribeMediaMutation>;
export type TranscribeMediaMutationResult = Apollo.MutationResult<TTranscribeMediaMutationResult>;
export type TranscribeMediaMutationOptions = Apollo.BaseMutationOptions<
  TTranscribeMediaMutationResult,
  TTranscribeMediaMutationVariables
>;
export const UpdateReadNotificationDocument = gql`
  mutation updateReadNotification($id: Int) {
    updateReadNotification(id: $id) {
      id
    }
  }
`;
export type TUpdateReadNotificationMutationFn = Apollo.MutationFunction<
  TUpdateReadNotificationMutationResult,
  TUpdateReadNotificationMutationVariables
>;

/**
 * __useUpdateReadNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReadNotificationMutation, { data, loading, error }] = useUpdateReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateReadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUpdateReadNotificationMutationResult,
    TUpdateReadNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateReadNotificationMutationResult, TUpdateReadNotificationMutationVariables>(
    UpdateReadNotificationDocument,
    options,
  );
}
export type UpdateReadNotificationMutationHookResult = ReturnType<typeof useUpdateReadNotificationMutation>;
export type UpdateReadNotificationMutationResult = Apollo.MutationResult<TUpdateReadNotificationMutationResult>;
export type UpdateReadNotificationMutationOptions = Apollo.BaseMutationOptions<
  TUpdateReadNotificationMutationResult,
  TUpdateReadNotificationMutationVariables
>;
export const DeleteNotificationDocument = gql`
  mutation deleteNotification($id: Int) {
    deleteNotification(id: $id) {
      id
    }
  }
`;
export type TDeleteNotificationMutationFn = Apollo.MutationFunction<
  TDeleteNotificationMutationResult,
  TDeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<TDeleteNotificationMutationResult, TDeleteNotificationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TDeleteNotificationMutationResult, TDeleteNotificationMutationVariables>(
    DeleteNotificationDocument,
    options,
  );
}
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<TDeleteNotificationMutationResult>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  TDeleteNotificationMutationResult,
  TDeleteNotificationMutationVariables
>;
export const RetrievedTranscribeDocument = gql`
  mutation retrievedTranscribe($jobId: String!, $mediaId: ID!) {
    retrievedTranscribe(jobId: $jobId, mediaId: $mediaId) {
      ...CoreMediaFields
    }
  }
  ${CoreMediaFieldsFragmentDoc}
`;
export type TRetrievedTranscribeMutationFn = Apollo.MutationFunction<
  TRetrievedTranscribeMutationResult,
  TRetrievedTranscribeMutationVariables
>;

/**
 * __useRetrievedTranscribeMutation__
 *
 * To run a mutation, you first call `useRetrievedTranscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrievedTranscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrievedTranscribeMutation, { data, loading, error }] = useRetrievedTranscribeMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useRetrievedTranscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<TRetrievedTranscribeMutationResult, TRetrievedTranscribeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TRetrievedTranscribeMutationResult, TRetrievedTranscribeMutationVariables>(
    RetrievedTranscribeDocument,
    options,
  );
}
export type RetrievedTranscribeMutationHookResult = ReturnType<typeof useRetrievedTranscribeMutation>;
export type RetrievedTranscribeMutationResult = Apollo.MutationResult<TRetrievedTranscribeMutationResult>;
export type RetrievedTranscribeMutationOptions = Apollo.BaseMutationOptions<
  TRetrievedTranscribeMutationResult,
  TRetrievedTranscribeMutationVariables
>;
export const TranslateMediaDocument = gql`
  mutation translateMedia($mediaId: ID!, $translateCode: [String]!) {
    translateMedia(mediaId: $mediaId, translateCode: $translateCode) {
      ...CoreMediaFields
    }
  }
  ${CoreMediaFieldsFragmentDoc}
`;
export type TTranslateMediaMutationFn = Apollo.MutationFunction<
  TTranslateMediaMutationResult,
  TTranslateMediaMutationVariables
>;

/**
 * __useTranslateMediaMutation__
 *
 * To run a mutation, you first call `useTranslateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranslateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [translateMediaMutation, { data, loading, error }] = useTranslateMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      translateCode: // value for 'translateCode'
 *   },
 * });
 */
export function useTranslateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<TTranslateMediaMutationResult, TTranslateMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TTranslateMediaMutationResult, TTranslateMediaMutationVariables>(
    TranslateMediaDocument,
    options,
  );
}
export type TranslateMediaMutationHookResult = ReturnType<typeof useTranslateMediaMutation>;
export type TranslateMediaMutationResult = Apollo.MutationResult<TTranslateMediaMutationResult>;
export type TranslateMediaMutationOptions = Apollo.BaseMutationOptions<
  TTranslateMediaMutationResult,
  TTranslateMediaMutationVariables
>;
export const UpdateNotificationsOpenedTimeDocument = gql`
  mutation updateNotificationsOpenedTime {
    updateNotificationsOpenedTime
  }
`;
export type TUpdateNotificationsOpenedTimeMutationFn = Apollo.MutationFunction<
  TUpdateNotificationsOpenedTimeMutationResult,
  TUpdateNotificationsOpenedTimeMutationVariables
>;

/**
 * __useUpdateNotificationsOpenedTimeMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsOpenedTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsOpenedTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsOpenedTimeMutation, { data, loading, error }] = useUpdateNotificationsOpenedTimeMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateNotificationsOpenedTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUpdateNotificationsOpenedTimeMutationResult,
    TUpdateNotificationsOpenedTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TUpdateNotificationsOpenedTimeMutationResult,
    TUpdateNotificationsOpenedTimeMutationVariables
  >(UpdateNotificationsOpenedTimeDocument, options);
}
export type UpdateNotificationsOpenedTimeMutationHookResult = ReturnType<
  typeof useUpdateNotificationsOpenedTimeMutation
>;
export type UpdateNotificationsOpenedTimeMutationResult =
  Apollo.MutationResult<TUpdateNotificationsOpenedTimeMutationResult>;
export type UpdateNotificationsOpenedTimeMutationOptions = Apollo.BaseMutationOptions<
  TUpdateNotificationsOpenedTimeMutationResult,
  TUpdateNotificationsOpenedTimeMutationVariables
>;
export const AmendEmailPreferencesDocument = gql`
  mutation amendEmailPreferences(
    $intercomId: ID!
    $subscribedHelp: Boolean!
    $subscribedUpdates: Boolean!
    $subscribedTransactional: Boolean!
  ) {
    amendEmailPreferences(
      intercomId: $intercomId
      subscribedHelp: $subscribedHelp
      subscribedUpdates: $subscribedUpdates
      subscribedTransactional: $subscribedTransactional
    ) {
      intercomId
      subscribedHelp
      subscribedUpdates
      subscribedTransactional
    }
  }
`;
export type TAmendEmailPreferencesMutationFn = Apollo.MutationFunction<
  TAmendEmailPreferencesMutationResult,
  TAmendEmailPreferencesMutationVariables
>;

/**
 * __useAmendEmailPreferencesMutation__
 *
 * To run a mutation, you first call `useAmendEmailPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendEmailPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendEmailPreferencesMutation, { data, loading, error }] = useAmendEmailPreferencesMutation({
 *   variables: {
 *      intercomId: // value for 'intercomId'
 *      subscribedHelp: // value for 'subscribedHelp'
 *      subscribedUpdates: // value for 'subscribedUpdates'
 *      subscribedTransactional: // value for 'subscribedTransactional'
 *   },
 * });
 */
export function useAmendEmailPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TAmendEmailPreferencesMutationResult,
    TAmendEmailPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAmendEmailPreferencesMutationResult, TAmendEmailPreferencesMutationVariables>(
    AmendEmailPreferencesDocument,
    options,
  );
}
export type AmendEmailPreferencesMutationHookResult = ReturnType<typeof useAmendEmailPreferencesMutation>;
export type AmendEmailPreferencesMutationResult = Apollo.MutationResult<TAmendEmailPreferencesMutationResult>;
export type AmendEmailPreferencesMutationOptions = Apollo.BaseMutationOptions<
  TAmendEmailPreferencesMutationResult,
  TAmendEmailPreferencesMutationVariables
>;
export const SaveTranscriptDocument = gql`
  mutation saveTranscript($projectId: ID!, $mediaId: ID!, $transcriptJson: String) {
    saveTranscript(projectId: $projectId, mediaId: $mediaId, transcriptJson: $transcriptJson) {
      id
    }
  }
`;
export type TSaveTranscriptMutationFn = Apollo.MutationFunction<
  TSaveTranscriptMutationResult,
  TSaveTranscriptMutationVariables
>;

/**
 * __useSaveTranscriptMutation__
 *
 * To run a mutation, you first call `useSaveTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTranscriptMutation, { data, loading, error }] = useSaveTranscriptMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      mediaId: // value for 'mediaId'
 *      transcriptJson: // value for 'transcriptJson'
 *   },
 * });
 */
export function useSaveTranscriptMutation(
  baseOptions?: Apollo.MutationHookOptions<TSaveTranscriptMutationResult, TSaveTranscriptMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSaveTranscriptMutationResult, TSaveTranscriptMutationVariables>(
    SaveTranscriptDocument,
    options,
  );
}
export type SaveTranscriptMutationHookResult = ReturnType<typeof useSaveTranscriptMutation>;
export type SaveTranscriptMutationResult = Apollo.MutationResult<TSaveTranscriptMutationResult>;
export type SaveTranscriptMutationOptions = Apollo.BaseMutationOptions<
  TSaveTranscriptMutationResult,
  TSaveTranscriptMutationVariables
>;
export const ChargeCreditDocument = gql`
  mutation chargeCredit($mediaId: ID!, $language: String!, $defaultSpeechApi: String!, $type: MediaAction!) {
    chargeCredit(mediaId: $mediaId, language: $language, defaultSpeechApi: $defaultSpeechApi, type: $type) {
      media {
        id
        status
        durationInSeconds
      }
      errorStatus
      errorMessage
      creditUsed
      creditCharged
      cardId
      language
      defaultSpeechApi
    }
  }
`;
export type TChargeCreditMutationFn = Apollo.MutationFunction<
  TChargeCreditMutationResult,
  TChargeCreditMutationVariables
>;

/**
 * __useChargeCreditMutation__
 *
 * To run a mutation, you first call `useChargeCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeCreditMutation, { data, loading, error }] = useChargeCreditMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      language: // value for 'language'
 *      defaultSpeechApi: // value for 'defaultSpeechApi'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useChargeCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<TChargeCreditMutationResult, TChargeCreditMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TChargeCreditMutationResult, TChargeCreditMutationVariables>(ChargeCreditDocument, options);
}
export type ChargeCreditMutationHookResult = ReturnType<typeof useChargeCreditMutation>;
export type ChargeCreditMutationResult = Apollo.MutationResult<TChargeCreditMutationResult>;
export type ChargeCreditMutationOptions = Apollo.BaseMutationOptions<
  TChargeCreditMutationResult,
  TChargeCreditMutationVariables
>;
export const SubScribePlanDocument = gql`
  mutation subScribePlan($planId: ID!) {
    subscribePlan(planId: $planId)
  }
`;
export type TSubScribePlanMutationFn = Apollo.MutationFunction<
  TSubScribePlanMutationResult,
  TSubScribePlanMutationVariables
>;

/**
 * __useSubScribePlanMutation__
 *
 * To run a mutation, you first call `useSubScribePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubScribePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subScribePlanMutation, { data, loading, error }] = useSubScribePlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useSubScribePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<TSubScribePlanMutationResult, TSubScribePlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TSubScribePlanMutationResult, TSubScribePlanMutationVariables>(
    SubScribePlanDocument,
    options,
  );
}
export type SubScribePlanMutationHookResult = ReturnType<typeof useSubScribePlanMutation>;
export type SubScribePlanMutationResult = Apollo.MutationResult<TSubScribePlanMutationResult>;
export type SubScribePlanMutationOptions = Apollo.BaseMutationOptions<
  TSubScribePlanMutationResult,
  TSubScribePlanMutationVariables
>;
export const UpdatePlanDocument = gql`
  mutation updatePlan($planId: ID!) {
    updatePlan(planId: $planId)
  }
`;
export type TUpdatePlanMutationFn = Apollo.MutationFunction<TUpdatePlanMutationResult, TUpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdatePlanMutationResult, TUpdatePlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdatePlanMutationResult, TUpdatePlanMutationVariables>(UpdatePlanDocument, options);
}
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<TUpdatePlanMutationResult>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<
  TUpdatePlanMutationResult,
  TUpdatePlanMutationVariables
>;
export const CreateSpeakerDocument = gql`
  mutation createSpeaker($mediaId: ID!, $name: String!, $color: String) {
    createSpeaker(mediaId: $mediaId, name: $name, color: $color) {
      id
      name
      color
    }
  }
`;
export type TCreateSpeakerMutationFn = Apollo.MutationFunction<
  TCreateSpeakerMutationResult,
  TCreateSpeakerMutationVariables
>;

/**
 * __useCreateSpeakerMutation__
 *
 * To run a mutation, you first call `useCreateSpeakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpeakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpeakerMutation, { data, loading, error }] = useCreateSpeakerMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCreateSpeakerMutation(
  baseOptions?: Apollo.MutationHookOptions<TCreateSpeakerMutationResult, TCreateSpeakerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCreateSpeakerMutationResult, TCreateSpeakerMutationVariables>(
    CreateSpeakerDocument,
    options,
  );
}
export type CreateSpeakerMutationHookResult = ReturnType<typeof useCreateSpeakerMutation>;
export type CreateSpeakerMutationResult = Apollo.MutationResult<TCreateSpeakerMutationResult>;
export type CreateSpeakerMutationOptions = Apollo.BaseMutationOptions<
  TCreateSpeakerMutationResult,
  TCreateSpeakerMutationVariables
>;
export const UpdateSpeakerDocument = gql`
  mutation updateSpeaker($speakerId: ID!, $name: String, $color: String) {
    updateSpeaker(speakerId: $speakerId, name: $name, color: $color) {
      ...CoreSpeakerFields
    }
  }
  ${CoreSpeakerFieldsFragmentDoc}
`;
export type TUpdateSpeakerMutationFn = Apollo.MutationFunction<
  TUpdateSpeakerMutationResult,
  TUpdateSpeakerMutationVariables
>;

/**
 * __useUpdateSpeakerMutation__
 *
 * To run a mutation, you first call `useUpdateSpeakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpeakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpeakerMutation, { data, loading, error }] = useUpdateSpeakerMutation({
 *   variables: {
 *      speakerId: // value for 'speakerId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useUpdateSpeakerMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateSpeakerMutationResult, TUpdateSpeakerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateSpeakerMutationResult, TUpdateSpeakerMutationVariables>(
    UpdateSpeakerDocument,
    options,
  );
}
export type UpdateSpeakerMutationHookResult = ReturnType<typeof useUpdateSpeakerMutation>;
export type UpdateSpeakerMutationResult = Apollo.MutationResult<TUpdateSpeakerMutationResult>;
export type UpdateSpeakerMutationOptions = Apollo.BaseMutationOptions<
  TUpdateSpeakerMutationResult,
  TUpdateSpeakerMutationVariables
>;
export const ChargeExtraCreditDocument = gql`
  mutation chargeExtraCredit(
    $extraType: ExtraEnum!
    $creditNeedCharge: Int
    $number: String
    $cardHolderName: String
    $cvc: String
    $expMonth: Int
    $expYear: Int
    $useSavedCard: Boolean!
  ) {
    chargeExtraCredit(
      extraType: $extraType
      creditNeedCharge: $creditNeedCharge
      number: $number
      cardHolderName: $cardHolderName
      cvc: $cvc
      expMonth: $expMonth
      expYear: $expYear
      useSavedCard: $useSavedCard
    )
  }
`;
export type TChargeExtraCreditMutationFn = Apollo.MutationFunction<
  TChargeExtraCreditMutationResult,
  TChargeExtraCreditMutationVariables
>;

/**
 * __useChargeExtraCreditMutation__
 *
 * To run a mutation, you first call `useChargeExtraCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeExtraCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeExtraCreditMutation, { data, loading, error }] = useChargeExtraCreditMutation({
 *   variables: {
 *      extraType: // value for 'extraType'
 *      creditNeedCharge: // value for 'creditNeedCharge'
 *      number: // value for 'number'
 *      cardHolderName: // value for 'cardHolderName'
 *      cvc: // value for 'cvc'
 *      expMonth: // value for 'expMonth'
 *      expYear: // value for 'expYear'
 *      useSavedCard: // value for 'useSavedCard'
 *   },
 * });
 */
export function useChargeExtraCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<TChargeExtraCreditMutationResult, TChargeExtraCreditMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TChargeExtraCreditMutationResult, TChargeExtraCreditMutationVariables>(
    ChargeExtraCreditDocument,
    options,
  );
}
export type ChargeExtraCreditMutationHookResult = ReturnType<typeof useChargeExtraCreditMutation>;
export type ChargeExtraCreditMutationResult = Apollo.MutationResult<TChargeExtraCreditMutationResult>;
export type ChargeExtraCreditMutationOptions = Apollo.BaseMutationOptions<
  TChargeExtraCreditMutationResult,
  TChargeExtraCreditMutationVariables
>;
export const UpdateAvatarDocument = gql`
  mutation updateAvatar($avatar: String!) {
    updateAvatar(avatar: $avatar) {
      user_id
      full_name
      email
      avatar
      invite_code
      planType {
        id
        label
        plan_enum
        full_label
      }
    }
  }
`;
export type TUpdateAvatarMutationFn = Apollo.MutationFunction<
  TUpdateAvatarMutationResult,
  TUpdateAvatarMutationVariables
>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<TUpdateAvatarMutationResult, TUpdateAvatarMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateAvatarMutationResult, TUpdateAvatarMutationVariables>(UpdateAvatarDocument, options);
}
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<TUpdateAvatarMutationResult>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<
  TUpdateAvatarMutationResult,
  TUpdateAvatarMutationVariables
>;
export const GetAvatarSignedUploadUrlDocument = gql`
  mutation getAvatarSignedUploadUrl($filename: String!, $mimeType: String!) {
    getAvatarSignedUploadUrl(filename: $filename, mimeType: $mimeType) {
      key
      url
    }
  }
`;
export type TGetAvatarSignedUploadUrlMutationFn = Apollo.MutationFunction<
  TGetAvatarSignedUploadUrlMutationResult,
  TGetAvatarSignedUploadUrlMutationVariables
>;

/**
 * __useGetAvatarSignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetAvatarSignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAvatarSignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAvatarSignedUploadUrlMutation, { data, loading, error }] = useGetAvatarSignedUploadUrlMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGetAvatarSignedUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TGetAvatarSignedUploadUrlMutationResult,
    TGetAvatarSignedUploadUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TGetAvatarSignedUploadUrlMutationResult, TGetAvatarSignedUploadUrlMutationVariables>(
    GetAvatarSignedUploadUrlDocument,
    options,
  );
}
export type GetAvatarSignedUploadUrlMutationHookResult = ReturnType<typeof useGetAvatarSignedUploadUrlMutation>;
export type GetAvatarSignedUploadUrlMutationResult = Apollo.MutationResult<TGetAvatarSignedUploadUrlMutationResult>;
export type GetAvatarSignedUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  TGetAvatarSignedUploadUrlMutationResult,
  TGetAvatarSignedUploadUrlMutationVariables
>;
export const ExportToSocialMediaDocument = gql`
  mutation exportToSocialMedia($projectId: ID!, $share_flatform: String!) {
    exportToSocialMedia(projectId: $projectId, share_flatform: $share_flatform) {
      id
      project_name
      description
    }
  }
`;
export type TExportToSocialMediaMutationFn = Apollo.MutationFunction<
  TExportToSocialMediaMutationResult,
  TExportToSocialMediaMutationVariables
>;

/**
 * __useExportToSocialMediaMutation__
 *
 * To run a mutation, you first call `useExportToSocialMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportToSocialMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportToSocialMediaMutation, { data, loading, error }] = useExportToSocialMediaMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      share_flatform: // value for 'share_flatform'
 *   },
 * });
 */
export function useExportToSocialMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<TExportToSocialMediaMutationResult, TExportToSocialMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TExportToSocialMediaMutationResult, TExportToSocialMediaMutationVariables>(
    ExportToSocialMediaDocument,
    options,
  );
}
export type ExportToSocialMediaMutationHookResult = ReturnType<typeof useExportToSocialMediaMutation>;
export type ExportToSocialMediaMutationResult = Apollo.MutationResult<TExportToSocialMediaMutationResult>;
export type ExportToSocialMediaMutationOptions = Apollo.BaseMutationOptions<
  TExportToSocialMediaMutationResult,
  TExportToSocialMediaMutationVariables
>;
export const UpdateStatusFailUploadDocument = gql`
  mutation updateStatusFailUpload($mediaId: ID!) {
    updateStatusFailUpload(mediaId: $mediaId) {
      id
      name
      url
      startTimeCode
      fps
      type
      height
      width
      durationInSeconds
      project_folder_id
      status
      file_upload_type
      project_id
    }
  }
`;
export type TUpdateStatusFailUploadMutationFn = Apollo.MutationFunction<
  TUpdateStatusFailUploadMutationResult,
  TUpdateStatusFailUploadMutationVariables
>;

/**
 * __useUpdateStatusFailUploadMutation__
 *
 * To run a mutation, you first call `useUpdateStatusFailUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusFailUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusFailUploadMutation, { data, loading, error }] = useUpdateStatusFailUploadMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useUpdateStatusFailUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TUpdateStatusFailUploadMutationResult,
    TUpdateStatusFailUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TUpdateStatusFailUploadMutationResult, TUpdateStatusFailUploadMutationVariables>(
    UpdateStatusFailUploadDocument,
    options,
  );
}
export type UpdateStatusFailUploadMutationHookResult = ReturnType<typeof useUpdateStatusFailUploadMutation>;
export type UpdateStatusFailUploadMutationResult = Apollo.MutationResult<TUpdateStatusFailUploadMutationResult>;
export type UpdateStatusFailUploadMutationOptions = Apollo.BaseMutationOptions<
  TUpdateStatusFailUploadMutationResult,
  TUpdateStatusFailUploadMutationVariables
>;
export const CanceledImportMediaDocument = gql`
  mutation canceledImportMedia($mediaId: ID!) {
    canceledImportMedia(mediaId: $mediaId) {
      id
      name
      url
      startTimeCode
      fps
      type
      height
      width
      durationInSeconds
      type
      archived
      project_folder_id
      status
      job_id
      start_time_transcription
      file_upload_type
      project_id
    }
  }
`;
export type TCanceledImportMediaMutationFn = Apollo.MutationFunction<
  TCanceledImportMediaMutationResult,
  TCanceledImportMediaMutationVariables
>;

/**
 * __useCanceledImportMediaMutation__
 *
 * To run a mutation, you first call `useCanceledImportMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCanceledImportMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [canceledImportMediaMutation, { data, loading, error }] = useCanceledImportMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useCanceledImportMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<TCanceledImportMediaMutationResult, TCanceledImportMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TCanceledImportMediaMutationResult, TCanceledImportMediaMutationVariables>(
    CanceledImportMediaDocument,
    options,
  );
}
export type CanceledImportMediaMutationHookResult = ReturnType<typeof useCanceledImportMediaMutation>;
export type CanceledImportMediaMutationResult = Apollo.MutationResult<TCanceledImportMediaMutationResult>;
export type CanceledImportMediaMutationOptions = Apollo.BaseMutationOptions<
  TCanceledImportMediaMutationResult,
  TCanceledImportMediaMutationVariables
>;
export const AmendAssemblyMediaDocument = gql`
  mutation amendAssemblyMedia($input: UpdateMediaInput!) {
    updateMedia(input: $input) {
      id
      name
      description
    }
  }
`;
export type TAmendAssemblyMediaMutationFn = Apollo.MutationFunction<
  TAmendAssemblyMediaMutationResult,
  TAmendAssemblyMediaMutationVariables
>;

/**
 * __useAmendAssemblyMediaMutation__
 *
 * To run a mutation, you first call `useAmendAssemblyMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendAssemblyMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendAssemblyMediaMutation, { data, loading, error }] = useAmendAssemblyMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAmendAssemblyMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<TAmendAssemblyMediaMutationResult, TAmendAssemblyMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAmendAssemblyMediaMutationResult, TAmendAssemblyMediaMutationVariables>(
    AmendAssemblyMediaDocument,
    options,
  );
}
export type AmendAssemblyMediaMutationHookResult = ReturnType<typeof useAmendAssemblyMediaMutation>;
export type AmendAssemblyMediaMutationResult = Apollo.MutationResult<TAmendAssemblyMediaMutationResult>;
export type AmendAssemblyMediaMutationOptions = Apollo.BaseMutationOptions<
  TAmendAssemblyMediaMutationResult,
  TAmendAssemblyMediaMutationVariables
>;
export const AddCartItemDocument = gql`
  mutation addCartItem($id: ID, $name: String) {
    cartItem(id: $id, name: $name) @client {
      id
      name
    }
  }
`;
export type TAddCartItemMutationFn = Apollo.MutationFunction<TAddCartItemMutationResult, TAddCartItemMutationVariables>;

/**
 * __useAddCartItemMutation__
 *
 * To run a mutation, you first call `useAddCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemMutation, { data, loading, error }] = useAddCartItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<TAddCartItemMutationResult, TAddCartItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TAddCartItemMutationResult, TAddCartItemMutationVariables>(AddCartItemDocument, options);
}
export type AddCartItemMutationHookResult = ReturnType<typeof useAddCartItemMutation>;
export type AddCartItemMutationResult = Apollo.MutationResult<TAddCartItemMutationResult>;
export type AddCartItemMutationOptions = Apollo.BaseMutationOptions<
  TAddCartItemMutationResult,
  TAddCartItemMutationVariables
>;
export const GetAssemblyProjectsDocument = gql`
  query getAssemblyProjects {
    getAssemblyProjects {
      ...CoreAssemblyProjectFields
      shares {
        id
        invitee
      }
      owner {
        full_name
        email
        avatar
      }
      imported_media {
        id
      }
    }
  }
  ${CoreAssemblyProjectFieldsFragmentDoc}
`;

/**
 * __useGetAssemblyProjectsQuery__
 *
 * To run a query within a React component, call `useGetAssemblyProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssemblyProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssemblyProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssemblyProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetAssemblyProjectsQueryResult, TGetAssemblyProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetAssemblyProjectsQueryResult, TGetAssemblyProjectsQueryVariables>(
    GetAssemblyProjectsDocument,
    options,
  );
}
export function useGetAssemblyProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetAssemblyProjectsQueryResult, TGetAssemblyProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetAssemblyProjectsQueryResult, TGetAssemblyProjectsQueryVariables>(
    GetAssemblyProjectsDocument,
    options,
  );
}
export type GetAssemblyProjectsQueryHookResult = ReturnType<typeof useGetAssemblyProjectsQuery>;
export type GetAssemblyProjectsLazyQueryHookResult = ReturnType<typeof useGetAssemblyProjectsLazyQuery>;
export type GetAssemblyProjectsQueryResult = Apollo.QueryResult<
  TGetAssemblyProjectsQueryResult,
  TGetAssemblyProjectsQueryVariables
>;
export const GetAssemblyProjectDocument = gql`
  query getAssemblyProject($projectId: ID!) {
    getAssemblyProject(projectId: $projectId) {
      ...CoreAssemblyProjectFields
      imported_media {
        ...CoreMediaFields
      }
      shares {
        id
        invitee
        privilege
      }
      folders {
        id
        name
        archived
        imported_media {
          ...CoreMediaFields
        }
      }
    }
  }
  ${CoreAssemblyProjectFieldsFragmentDoc}
  ${CoreMediaFieldsFragmentDoc}
`;

/**
 * __useGetAssemblyProjectQuery__
 *
 * To run a query within a React component, call `useGetAssemblyProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssemblyProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssemblyProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetAssemblyProjectQuery(
  baseOptions: Apollo.QueryHookOptions<TGetAssemblyProjectQueryResult, TGetAssemblyProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetAssemblyProjectQueryResult, TGetAssemblyProjectQueryVariables>(
    GetAssemblyProjectDocument,
    options,
  );
}
export function useGetAssemblyProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetAssemblyProjectQueryResult, TGetAssemblyProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetAssemblyProjectQueryResult, TGetAssemblyProjectQueryVariables>(
    GetAssemblyProjectDocument,
    options,
  );
}
export type GetAssemblyProjectQueryHookResult = ReturnType<typeof useGetAssemblyProjectQuery>;
export type GetAssemblyProjectLazyQueryHookResult = ReturnType<typeof useGetAssemblyProjectLazyQuery>;
export type GetAssemblyProjectQueryResult = Apollo.QueryResult<
  TGetAssemblyProjectQueryResult,
  TGetAssemblyProjectQueryVariables
>;
export const GetAssemblyMediaDocument = gql`
  query getAssemblyMedia($mediaId: ID!) {
    getAssemblyMedia(mediaId: $mediaId) {
      ...CoreMediaFields
      folder {
        id
        name
        archived
      }
      transcript {
        ...CoreTranscriptFields
      }
    }
  }
  ${CoreMediaFieldsFragmentDoc}
  ${CoreTranscriptFieldsFragmentDoc}
`;

/**
 * __useGetAssemblyMediaQuery__
 *
 * To run a query within a React component, call `useGetAssemblyMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssemblyMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssemblyMediaQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useGetAssemblyMediaQuery(
  baseOptions: Apollo.QueryHookOptions<TGetAssemblyMediaQueryResult, TGetAssemblyMediaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetAssemblyMediaQueryResult, TGetAssemblyMediaQueryVariables>(
    GetAssemblyMediaDocument,
    options,
  );
}
export function useGetAssemblyMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetAssemblyMediaQueryResult, TGetAssemblyMediaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetAssemblyMediaQueryResult, TGetAssemblyMediaQueryVariables>(
    GetAssemblyMediaDocument,
    options,
  );
}
export type GetAssemblyMediaQueryHookResult = ReturnType<typeof useGetAssemblyMediaQuery>;
export type GetAssemblyMediaLazyQueryHookResult = ReturnType<typeof useGetAssemblyMediaLazyQuery>;
export type GetAssemblyMediaQueryResult = Apollo.QueryResult<
  TGetAssemblyMediaQueryResult,
  TGetAssemblyMediaQueryVariables
>;
export const GetLatestAssemblyDocument = gql`
  query getLatestAssembly($projectId: ID!) {
    getLatestAssembly(projectId: $projectId) {
      ...CoreAssemblyFields
      segments {
        ...CoreSegmentFields
        imported_media {
          ...CoreMediaFields
        }
        selection {
          ...CoreSelectionFields
        }
        annotations {
          ...CoreAnnotationFields
          user {
            ...CoreUserFields
          }
        }
        reactions {
          ...CoreReactionFields
        }
        speaker {
          id
          name
          color
        }
      }
    }
  }
  ${CoreAssemblyFieldsFragmentDoc}
  ${CoreSegmentFieldsFragmentDoc}
  ${CoreMediaFieldsFragmentDoc}
  ${CoreSelectionFieldsFragmentDoc}
  ${CoreAnnotationFieldsFragmentDoc}
  ${CoreUserFieldsFragmentDoc}
  ${CoreReactionFieldsFragmentDoc}
`;

/**
 * __useGetLatestAssemblyQuery__
 *
 * To run a query within a React component, call `useGetLatestAssemblyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAssemblyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAssemblyQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetLatestAssemblyQuery(
  baseOptions: Apollo.QueryHookOptions<TGetLatestAssemblyQueryResult, TGetLatestAssemblyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetLatestAssemblyQueryResult, TGetLatestAssemblyQueryVariables>(
    GetLatestAssemblyDocument,
    options,
  );
}
export function useGetLatestAssemblyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetLatestAssemblyQueryResult, TGetLatestAssemblyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetLatestAssemblyQueryResult, TGetLatestAssemblyQueryVariables>(
    GetLatestAssemblyDocument,
    options,
  );
}
export type GetLatestAssemblyQueryHookResult = ReturnType<typeof useGetLatestAssemblyQuery>;
export type GetLatestAssemblyLazyQueryHookResult = ReturnType<typeof useGetLatestAssemblyLazyQuery>;
export type GetLatestAssemblyQueryResult = Apollo.QueryResult<
  TGetLatestAssemblyQueryResult,
  TGetLatestAssemblyQueryVariables
>;
export const GetActiveVisitorsDocument = gql`
  query getActiveVisitors($projectId: ID!, $session: ID!) {
    getActiveVisitors(projectId: $projectId, session: $session) {
      ...CoreVisitorFields
    }
  }
  ${CoreVisitorFieldsFragmentDoc}
`;

/**
 * __useGetActiveVisitorsQuery__
 *
 * To run a query within a React component, call `useGetActiveVisitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveVisitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveVisitorsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      session: // value for 'session'
 *   },
 * });
 */
export function useGetActiveVisitorsQuery(
  baseOptions: Apollo.QueryHookOptions<TGetActiveVisitorsQueryResult, TGetActiveVisitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetActiveVisitorsQueryResult, TGetActiveVisitorsQueryVariables>(
    GetActiveVisitorsDocument,
    options,
  );
}
export function useGetActiveVisitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetActiveVisitorsQueryResult, TGetActiveVisitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetActiveVisitorsQueryResult, TGetActiveVisitorsQueryVariables>(
    GetActiveVisitorsDocument,
    options,
  );
}
export type GetActiveVisitorsQueryHookResult = ReturnType<typeof useGetActiveVisitorsQuery>;
export type GetActiveVisitorsLazyQueryHookResult = ReturnType<typeof useGetActiveVisitorsLazyQuery>;
export type GetActiveVisitorsQueryResult = Apollo.QueryResult<
  TGetActiveVisitorsQueryResult,
  TGetActiveVisitorsQueryVariables
>;
export const GetLanguagesDocument = gql`
  query getLanguages {
    getLanguages {
      languages {
        ...CoreLanguageFields
        languageRegions {
          ...CoreLanguageRegionFields
        }
      }
      lastUsedTranscriptionLanguageRegion {
        ...CoreLanguageRegionFields
        language {
          ...CoreLanguageFields
        }
      }
      lastUsedTranslationLanguagesRegion {
        ...CoreLanguageFields
        languageRegions {
          ...CoreLanguageRegionFields
        }
      }
    }
  }
  ${CoreLanguageFieldsFragmentDoc}
  ${CoreLanguageRegionFieldsFragmentDoc}
`;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetLanguagesQueryResult, TGetLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetLanguagesQueryResult, TGetLanguagesQueryVariables>(GetLanguagesDocument, options);
}
export function useGetLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetLanguagesQueryResult, TGetLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetLanguagesQueryResult, TGetLanguagesQueryVariables>(GetLanguagesDocument, options);
}
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesQueryResult = Apollo.QueryResult<TGetLanguagesQueryResult, TGetLanguagesQueryVariables>;
export const GetNotificationsDocument = gql`
  query getNotifications($skip: Int!, $take: Int!) {
    getNotifications(skip: $skip, take: $take) {
      totalUnseen
      listNotification {
        ...CoreNotificationFields
        actor {
          ...CoreUserFields
        }
      }
    }
  }
  ${CoreNotificationFieldsFragmentDoc}
  ${CoreUserFieldsFragmentDoc}
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<TGetNotificationsQueryResult, TGetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetNotificationsQueryResult, TGetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  );
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetNotificationsQueryResult, TGetNotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetNotificationsQueryResult, TGetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options,
  );
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  TGetNotificationsQueryResult,
  TGetNotificationsQueryVariables
>;
export const GetStatusTranscribeDocument = gql`
  query getStatusTranscribe($jobId: String) {
    getStatusTranscribe(jobId: $jobId) {
      id
      mediaId
      status
      statusText
    }
  }
`;

/**
 * __useGetStatusTranscribeQuery__
 *
 * To run a query within a React component, call `useGetStatusTranscribeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusTranscribeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusTranscribeQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetStatusTranscribeQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetStatusTranscribeQueryResult, TGetStatusTranscribeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetStatusTranscribeQueryResult, TGetStatusTranscribeQueryVariables>(
    GetStatusTranscribeDocument,
    options,
  );
}
export function useGetStatusTranscribeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetStatusTranscribeQueryResult, TGetStatusTranscribeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetStatusTranscribeQueryResult, TGetStatusTranscribeQueryVariables>(
    GetStatusTranscribeDocument,
    options,
  );
}
export type GetStatusTranscribeQueryHookResult = ReturnType<typeof useGetStatusTranscribeQuery>;
export type GetStatusTranscribeLazyQueryHookResult = ReturnType<typeof useGetStatusTranscribeLazyQuery>;
export type GetStatusTranscribeQueryResult = Apollo.QueryResult<
  TGetStatusTranscribeQueryResult,
  TGetStatusTranscribeQueryVariables
>;
export const GetUserProfileDocument = gql`
  query getUserProfile {
    getUserProfile {
      ...CoreUserFields
      planType {
        id
        label
        plan_enum
        full_label
      }
    }
  }
  ${CoreUserFieldsFragmentDoc}
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetUserProfileQueryResult, TGetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetUserProfileQueryResult, TGetUserProfileQueryVariables>(GetUserProfileDocument, options);
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetUserProfileQueryResult, TGetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetUserProfileQueryResult, TGetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  );
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<TGetUserProfileQueryResult, TGetUserProfileQueryVariables>;
export const GetPlanDocument = gql`
  query getPlan {
    getPlan {
      ...CorePlanTypeFields
    }
  }
  ${CorePlanTypeFieldsFragmentDoc}
`;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions?: Apollo.QueryHookOptions<TGetPlanQueryResult, TGetPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetPlanQueryResult, TGetPlanQueryVariables>(GetPlanDocument, options);
}
export function useGetPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetPlanQueryResult, TGetPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetPlanQueryResult, TGetPlanQueryVariables>(GetPlanDocument, options);
}
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<TGetPlanQueryResult, TGetPlanQueryVariables>;
export const GetSpeakerDocument = gql`
  query getSpeaker($mediaId: ID!) {
    getSpeaker(mediaId: $mediaId) {
      ...CoreSpeakerFields
    }
  }
  ${CoreSpeakerFieldsFragmentDoc}
`;

/**
 * __useGetSpeakerQuery__
 *
 * To run a query within a React component, call `useGetSpeakerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakerQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useGetSpeakerQuery(
  baseOptions: Apollo.QueryHookOptions<TGetSpeakerQueryResult, TGetSpeakerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetSpeakerQueryResult, TGetSpeakerQueryVariables>(GetSpeakerDocument, options);
}
export function useGetSpeakerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetSpeakerQueryResult, TGetSpeakerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetSpeakerQueryResult, TGetSpeakerQueryVariables>(GetSpeakerDocument, options);
}
export type GetSpeakerQueryHookResult = ReturnType<typeof useGetSpeakerQuery>;
export type GetSpeakerLazyQueryHookResult = ReturnType<typeof useGetSpeakerLazyQuery>;
export type GetSpeakerQueryResult = Apollo.QueryResult<TGetSpeakerQueryResult, TGetSpeakerQueryVariables>;
export const GetShareProjectDocument = gql`
  query getShareProject($id: Int!) {
    getShareProject(id: $id) {
      id
      project_id
      project_name
      description
      gif_preview
      video_preview
      video_status
      img_preview
      share_flatform
      creation_date
      user {
        full_name
        avatar
      }
    }
  }
`;

/**
 * __useGetShareProjectQuery__
 *
 * To run a query within a React component, call `useGetShareProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShareProjectQuery(
  baseOptions: Apollo.QueryHookOptions<TGetShareProjectQueryResult, TGetShareProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetShareProjectQueryResult, TGetShareProjectQueryVariables>(GetShareProjectDocument, options);
}
export function useGetShareProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetShareProjectQueryResult, TGetShareProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetShareProjectQueryResult, TGetShareProjectQueryVariables>(
    GetShareProjectDocument,
    options,
  );
}
export type GetShareProjectQueryHookResult = ReturnType<typeof useGetShareProjectQuery>;
export type GetShareProjectLazyQueryHookResult = ReturnType<typeof useGetShareProjectLazyQuery>;
export type GetShareProjectQueryResult = Apollo.QueryResult<
  TGetShareProjectQueryResult,
  TGetShareProjectQueryVariables
>;
export const GetExportDocument = gql`
  query getExport($projectId: ID!, $fileType: String!, $extension: String!) {
    getExport(projectId: $projectId, fileType: $fileType, extension: $extension) {
      base64Payload
      url
      fileType
      extension
    }
  }
`;

/**
 * __useGetExportQuery__
 *
 * To run a query within a React component, call `useGetExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      fileType: // value for 'fileType'
 *      extension: // value for 'extension'
 *   },
 * });
 */
export function useGetExportQuery(
  baseOptions: Apollo.QueryHookOptions<TGetExportQueryResult, TGetExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetExportQueryResult, TGetExportQueryVariables>(GetExportDocument, options);
}
export function useGetExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetExportQueryResult, TGetExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetExportQueryResult, TGetExportQueryVariables>(GetExportDocument, options);
}
export type GetExportQueryHookResult = ReturnType<typeof useGetExportQuery>;
export type GetExportLazyQueryHookResult = ReturnType<typeof useGetExportLazyQuery>;
export type GetExportQueryResult = Apollo.QueryResult<TGetExportQueryResult, TGetExportQueryVariables>;
export const GetPaymentLinkDocument = gql`
  query getPaymentLink {
    getPaymentLink
  }
`;

/**
 * __useGetPaymentLinkQuery__
 *
 * To run a query within a React component, call `useGetPaymentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetPaymentLinkQueryResult, TGetPaymentLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetPaymentLinkQueryResult, TGetPaymentLinkQueryVariables>(GetPaymentLinkDocument, options);
}
export function useGetPaymentLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetPaymentLinkQueryResult, TGetPaymentLinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetPaymentLinkQueryResult, TGetPaymentLinkQueryVariables>(
    GetPaymentLinkDocument,
    options,
  );
}
export type GetPaymentLinkQueryHookResult = ReturnType<typeof useGetPaymentLinkQuery>;
export type GetPaymentLinkLazyQueryHookResult = ReturnType<typeof useGetPaymentLinkLazyQuery>;
export type GetPaymentLinkQueryResult = Apollo.QueryResult<TGetPaymentLinkQueryResult, TGetPaymentLinkQueryVariables>;
export const GetAssemblyProjectPublicDataDocument = gql`
  query getAssemblyProjectPublicData($projectId: ID!) {
    getAssemblyProjectPublicData(projectId: $projectId) {
      name
      thumb
    }
  }
`;

/**
 * __useGetAssemblyProjectPublicDataQuery__
 *
 * To run a query within a React component, call `useGetAssemblyProjectPublicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssemblyProjectPublicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssemblyProjectPublicDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetAssemblyProjectPublicDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    TGetAssemblyProjectPublicDataQueryResult,
    TGetAssemblyProjectPublicDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetAssemblyProjectPublicDataQueryResult, TGetAssemblyProjectPublicDataQueryVariables>(
    GetAssemblyProjectPublicDataDocument,
    options,
  );
}
export function useGetAssemblyProjectPublicDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TGetAssemblyProjectPublicDataQueryResult,
    TGetAssemblyProjectPublicDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetAssemblyProjectPublicDataQueryResult, TGetAssemblyProjectPublicDataQueryVariables>(
    GetAssemblyProjectPublicDataDocument,
    options,
  );
}
export type GetAssemblyProjectPublicDataQueryHookResult = ReturnType<typeof useGetAssemblyProjectPublicDataQuery>;
export type GetAssemblyProjectPublicDataLazyQueryHookResult = ReturnType<
  typeof useGetAssemblyProjectPublicDataLazyQuery
>;
export type GetAssemblyProjectPublicDataQueryResult = Apollo.QueryResult<
  TGetAssemblyProjectPublicDataQueryResult,
  TGetAssemblyProjectPublicDataQueryVariables
>;
export const GetIsOpenListDictDocument = gql`
  query getIsOpenListDict {
    isOpenListDict @client
  }
`;

/**
 * __useGetIsOpenListDictQuery__
 *
 * To run a query within a React component, call `useGetIsOpenListDictQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsOpenListDictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsOpenListDictQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsOpenListDictQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetIsOpenListDictQueryResult, TGetIsOpenListDictQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetIsOpenListDictQueryResult, TGetIsOpenListDictQueryVariables>(
    GetIsOpenListDictDocument,
    options,
  );
}
export function useGetIsOpenListDictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetIsOpenListDictQueryResult, TGetIsOpenListDictQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetIsOpenListDictQueryResult, TGetIsOpenListDictQueryVariables>(
    GetIsOpenListDictDocument,
    options,
  );
}
export type GetIsOpenListDictQueryHookResult = ReturnType<typeof useGetIsOpenListDictQuery>;
export type GetIsOpenListDictLazyQueryHookResult = ReturnType<typeof useGetIsOpenListDictLazyQuery>;
export type GetIsOpenListDictQueryResult = Apollo.QueryResult<
  TGetIsOpenListDictQueryResult,
  TGetIsOpenListDictQueryVariables
>;
export const GetCartItemsDocument = gql`
  query getCartItems($id: String) {
    cartItems(id: $id) @client {
      id
      name
    }
  }
`;

/**
 * __useGetCartItemsQuery__
 *
 * To run a query within a React component, call `useGetCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCartItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetCartItemsQueryResult, TGetCartItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetCartItemsQueryResult, TGetCartItemsQueryVariables>(GetCartItemsDocument, options);
}
export function useGetCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetCartItemsQueryResult, TGetCartItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetCartItemsQueryResult, TGetCartItemsQueryVariables>(GetCartItemsDocument, options);
}
export type GetCartItemsQueryHookResult = ReturnType<typeof useGetCartItemsQuery>;
export type GetCartItemsLazyQueryHookResult = ReturnType<typeof useGetCartItemsLazyQuery>;
export type GetCartItemsQueryResult = Apollo.QueryResult<TGetCartItemsQueryResult, TGetCartItemsQueryVariables>;
export const GetIntercomHashDocument = gql`
  query getIntercomHash {
    getIntercomHash
  }
`;

/**
 * __useGetIntercomHashQuery__
 *
 * To run a query within a React component, call `useGetIntercomHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntercomHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntercomHashQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntercomHashQuery(
  baseOptions?: Apollo.QueryHookOptions<TGetIntercomHashQueryResult, TGetIntercomHashQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TGetIntercomHashQueryResult, TGetIntercomHashQueryVariables>(GetIntercomHashDocument, options);
}
export function useGetIntercomHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TGetIntercomHashQueryResult, TGetIntercomHashQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TGetIntercomHashQueryResult, TGetIntercomHashQueryVariables>(
    GetIntercomHashDocument,
    options,
  );
}
export type GetIntercomHashQueryHookResult = ReturnType<typeof useGetIntercomHashQuery>;
export type GetIntercomHashLazyQueryHookResult = ReturnType<typeof useGetIntercomHashLazyQuery>;
export type GetIntercomHashQueryResult = Apollo.QueryResult<
  TGetIntercomHashQueryResult,
  TGetIntercomHashQueryVariables
>;
export const AssemblyUpdatedDocument = gql`
  subscription assemblyUpdated($projectId: ID!) {
    assemblyUpdated(projectId: $projectId) {
      id
      version
      segments {
        ...CoreSegmentFields
        imported_media {
          ...CoreMediaFields
        }
        selection {
          ...CoreSelectionFields
        }
        annotations {
          ...CoreAnnotationFields
          user {
            ...CoreUserFields
          }
        }
        reactions {
          ...CoreReactionFields
        }
        speaker {
          id
          name
          color
        }
      }
    }
  }
  ${CoreSegmentFieldsFragmentDoc}
  ${CoreMediaFieldsFragmentDoc}
  ${CoreSelectionFieldsFragmentDoc}
  ${CoreAnnotationFieldsFragmentDoc}
  ${CoreUserFieldsFragmentDoc}
  ${CoreReactionFieldsFragmentDoc}
`;

/**
 * __useAssemblyUpdatedSubscription__
 *
 * To run a query within a React component, call `useAssemblyUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAssemblyUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssemblyUpdatedSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAssemblyUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TAssemblyUpdatedSubscriptionResult,
    TAssemblyUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TAssemblyUpdatedSubscriptionResult, TAssemblyUpdatedSubscriptionVariables>(
    AssemblyUpdatedDocument,
    options,
  );
}
export type AssemblyUpdatedSubscriptionHookResult = ReturnType<typeof useAssemblyUpdatedSubscription>;
export type AssemblyUpdatedSubscriptionResult = Apollo.SubscriptionResult<TAssemblyUpdatedSubscriptionResult>;
export const ActiveUpdatedDocument = gql`
  subscription activeUpdated($projectId: ID!) {
    activeUpdated(projectId: $projectId) {
      project_id
      visitors {
        ...CoreVisitorFields
      }
    }
  }
  ${CoreVisitorFieldsFragmentDoc}
`;

/**
 * __useActiveUpdatedSubscription__
 *
 * To run a query within a React component, call `useActiveUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActiveUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveUpdatedSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useActiveUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<TActiveUpdatedSubscriptionResult, TActiveUpdatedSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TActiveUpdatedSubscriptionResult, TActiveUpdatedSubscriptionVariables>(
    ActiveUpdatedDocument,
    options,
  );
}
export type ActiveUpdatedSubscriptionHookResult = ReturnType<typeof useActiveUpdatedSubscription>;
export type ActiveUpdatedSubscriptionResult = Apollo.SubscriptionResult<TActiveUpdatedSubscriptionResult>;
export const ProcessUploadFileDocument = gql`
  subscription processUploadFile($mediaId: ID!) {
    processUploadFile(mediaId: $mediaId) {
      mediaId
      percentageProcessResponse
      errorMessage
    }
  }
`;

/**
 * __useProcessUploadFileSubscription__
 *
 * To run a query within a React component, call `useProcessUploadFileSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProcessUploadFileSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessUploadFileSubscription({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useProcessUploadFileSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TProcessUploadFileSubscriptionResult,
    TProcessUploadFileSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TProcessUploadFileSubscriptionResult, TProcessUploadFileSubscriptionVariables>(
    ProcessUploadFileDocument,
    options,
  );
}
export type ProcessUploadFileSubscriptionHookResult = ReturnType<typeof useProcessUploadFileSubscription>;
export type ProcessUploadFileSubscriptionResult = Apollo.SubscriptionResult<TProcessUploadFileSubscriptionResult>;
export const NotificationAddedDocument = gql`
  subscription notificationAdded {
    notificationAdded {
      ...CoreNotificationFields
      actor {
        full_name
        avatar
      }
    }
  }
  ${CoreNotificationFieldsFragmentDoc}
`;

/**
 * __useNotificationAddedSubscription__
 *
 * To run a query within a React component, call `useNotificationAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TNotificationAddedSubscriptionResult,
    TNotificationAddedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TNotificationAddedSubscriptionResult, TNotificationAddedSubscriptionVariables>(
    NotificationAddedDocument,
    options,
  );
}
export type NotificationAddedSubscriptionHookResult = ReturnType<typeof useNotificationAddedSubscription>;
export type NotificationAddedSubscriptionResult = Apollo.SubscriptionResult<TNotificationAddedSubscriptionResult>;
export const ProcessTranslateMultipleMediaDocument = gql`
  subscription processTranslateMultipleMedia($mediaId: ID!) {
    processTranslateMultipleMedia(mediaId: $mediaId) {
      progress
      mediaIdTranslate
      media {
        ...CoreMediaFields
      }
    }
  }
  ${CoreMediaFieldsFragmentDoc}
`;

/**
 * __useProcessTranslateMultipleMediaSubscription__
 *
 * To run a query within a React component, call `useProcessTranslateMultipleMediaSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProcessTranslateMultipleMediaSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessTranslateMultipleMediaSubscription({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useProcessTranslateMultipleMediaSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TProcessTranslateMultipleMediaSubscriptionResult,
    TProcessTranslateMultipleMediaSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TProcessTranslateMultipleMediaSubscriptionResult,
    TProcessTranslateMultipleMediaSubscriptionVariables
  >(ProcessTranslateMultipleMediaDocument, options);
}
export type ProcessTranslateMultipleMediaSubscriptionHookResult = ReturnType<
  typeof useProcessTranslateMultipleMediaSubscription
>;
export type ProcessTranslateMultipleMediaSubscriptionResult =
  Apollo.SubscriptionResult<TProcessTranslateMultipleMediaSubscriptionResult>;
export const OnMediaStatusChangedDocument = gql`
  subscription onMediaStatusChanged($projectId: ID!) {
    onMediaStatusChanged(projectId: $projectId) {
      projectId
      mediaId
      status
      transcodingPercentageValue
    }
  }
`;

/**
 * __useOnMediaStatusChangedSubscription__
 *
 * To run a query within a React component, call `useOnMediaStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMediaStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMediaStatusChangedSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useOnMediaStatusChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TOnMediaStatusChangedSubscriptionResult,
    TOnMediaStatusChangedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TOnMediaStatusChangedSubscriptionResult, TOnMediaStatusChangedSubscriptionVariables>(
    OnMediaStatusChangedDocument,
    options,
  );
}
export type OnMediaStatusChangedSubscriptionHookResult = ReturnType<typeof useOnMediaStatusChangedSubscription>;
export type OnMediaStatusChangedSubscriptionResult = Apollo.SubscriptionResult<TOnMediaStatusChangedSubscriptionResult>;
